import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react"

import { KButton, KSpan } from "kahuna-base-react-components"
import { TrackRegistryConsentFile } from "../../../types"
import { releases } from "../../../apis"
import Loading from "../Loading"
import { errorToastOptions } from "../../../constants"
import { toast } from "react-toastify"
import { lang } from "../../../constants/languages"

interface UploadFileComponentProps {
  trackRegistryConsentFileList: TrackRegistryConsentFile[]
  setTrackRegistryConsentFileList: (files: TrackRegistryConsentFile[]) => void
  isrc: string
  upc: string
}

const UploadContributorConsentFileComponent: FC<UploadFileComponentProps> = (props) => {
  const { trackRegistryConsentFileList, setTrackRegistryConsentFileList, isrc, upc } = props

  const [loading, setLoading] = useState(false)

  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleBrowseClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileInput = event.target

    if (fileInput.files && fileInput.files.length > 0) {
      setLoading(true)
      const file = fileInput.files[0]

      const formData = new FormData()

      formData.append("file", file)

      releases(`/track-registry-consent-file/upload/?upc=${upc}&isrc=${isrc}`, "post", formData).then((resp) => {
        if (resp.data) {
          setTrackRegistryConsentFileList(resp.data)
        }
        setLoading(false)
      })
    }
  }

  const onRemoveFile = (id: number) => {
    setLoading(true)
    if (!window.confirm("Are you sure?")) {
      return
    }

    releases(`/track-registry-consent-file/delete/?id=${id}`, "delete").then((resp) => {
      if (resp.data.success) {
        setTrackRegistryConsentFileList([...trackRegistryConsentFileList].filter((file) => file.id !== id))
      } else {
        toast.error(lang.catalog.track_detail.error_on_removing_file, errorToastOptions)
      }
      setLoading(false)
    })
  }

  const onDownloadFile = (file: TrackRegistryConsentFile) => {
    window.open(file.file_url, "_blank")
  }

  const renderFileList = () => (
    <div className="flex flex-col mt-4" style={{ width: "100%" }}>
      {trackRegistryConsentFileList.map((file: TrackRegistryConsentFile, index: number) => {
        return (
          <div
            className="p-2 gap-4 flex justify-between"
            key={`file-${file}-${index}`}
            style={{ width: "100%", display: "flex", borderTop: "1px dashed grey" }}
          >
            <div className="flex gap-3">
              <KButton
                icon={"/account_icons/download-line.svg"}
                shadowDisabled={true}
                padding="0px"
                width="20px"
                height="20px"
                background="transparent"
                onClick={() => onDownloadFile(file)}
              />

              <div className="flex flex-row items-center justify-start gap-3">
                <div className="flex flex-col">
                  <KSpan text={file.title} fontWeight={500} color="#111" />
                </div>
              </div>
            </div>

            <KButton
              icon={"/catalog_icons/delete-bin.svg"}
              shadowDisabled={true}
              padding="0px"
              width="20px"
              height="20px"
              background="transparent"
              onClick={() => {
                if (file.id) onRemoveFile(file.id)
              }}
            />
          </div>
        )
      })}
    </div>
  )

  return (
    <div className="flex flex-col items-center justify-center gap-5 p-8">
      {loading && <Loading />}
      <div className={"flex justify-center"} style={{ height: 40 }}>
        <img src={"/catalog_icons/upload-cloud.svg"} />
      </div>
      <div className={"flex flex-col gap-1 justify-center "}>
        <div className="justify-center flex">
          <KSpan text={lang.catalog.track_detail.upload_consent_file_title} color={"#111"} fontWeight={500} />
        </div>
        <KSpan text={lang.catalog.track_detail.upload_consent_file_desc} fontSize={12} lineHeight="16px" />
      </div>
      <div
        style={{
          pointerEvents: "auto"
        }}
      >
        <KButton
          text={lang.catalog.track_detail.upload_consent_file_button}
          onClick={handleBrowseClick}
          height="32px"
          padding="6px 10px"
          background="#FFF"
          textColor="#111"
        />
      </div>
      <input
        style={{ display: "none" }}
        ref={fileInputRef}
        id="file"
        type="file"
        accept=".pdf"
        onChange={(event) => handleFileChange(event)}
      />
      {trackRegistryConsentFileList.length > 0 && renderFileList()}
    </div>
  )
}

export default UploadContributorConsentFileComponent
