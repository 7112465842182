import { KButton, KInput, KSpan, KTitleSpan, KTooltip } from "kahuna-base-react-components"
//@ts-ignore
import EditIcon from "../../../../../assets/release-registry-icons/edit-dark.svg"
import React, { useEffect, useState } from "react"
import { useOrchestrator } from "../ReleaseRegistryContextProvider"
//@ts-ignore
import DefaultCoverImage from "../../../../ui/img/default-product.jpeg"
//@ts-ignore
import VectorIcon from "../../../../../assets/release-registry-icons/vector.svg"
//@ts-ignore
import ChevronDownIcon from "../../../../../assets/release-registry-icons/chevron-down-dark.svg"
//@ts-ignore
import ChevronUpIcon from "../../../../../assets/release-registry-icons/chevron-up-dark.svg"
//@ts-ignore
import CalendarIcon from "../../../../../assets/release-registry-icons/calendar.svg"
//@ts-ignore
import CheckGreenIcon from "../../../../../assets/release-registry-icons/check-green.svg"
//@ts-ignore
import AlertIcon from "../../../../../assets/release-registry-icons/alert-triangle.svg"
//@ts-ignore
import CompletedIcon from "../../../../../assets/release-registry-icons/completed.svg"
//@ts-ignore
import IncompleteIcon from "../../../../../assets/release-registry-icons/incomplete.svg"
//@ts-ignore
import SpotifyLogo from "../../../../../assets/release-platform-icons/SpotifyLogo.png"
import {
  checkMissingTrackFields,
  formatDate2,
  formatDateTurkish,
  formatDuration,
  getDuration,
  mapListToCamelCase,
  turkishCharMap
} from "../../../../../utility"
import { state51GenreMapping, state51LanguageMapping, successToastOptions } from "../../../../../constants"
import { releases } from "../../../../../apis"
import { ReleaseRegistryPages } from "../../../../../actions/types"
import { useNavigate } from "react-router-dom"
import AudioPlayerProgress from "../component/AudioPlayerProgress"
import Loading from "../../../../ui/Loading"
import ErrorModal from "../modal/ErrorModal"
import { toast } from "react-toastify"
import { TerritoryDsp } from "../../../../../types"

const notAllowedProductRelationships = ["company", "default-offer", "distributor", "offers", "performers", "tracks"]

const Summary = () => {
  const {
    options,
    releaseForm,
    errorText,
    setErrorText,
    distributedRights,
    setDistributedRights,
    setSelectedNavigationTab,
    setRedirectedFromSummary,
    setReleaseForm,
    isPublishing,
    summaryPublishingText,
    bmwCustomIdKeyword
  } = useOrchestrator()
  const navigate = useNavigate()
  const [playingAudioRefs, setPlayingAudioRefs] = useState<HTMLAudioElement[]>([])

  const [checkedBoxes, setCheckedBoxes] = useState<{
    first: boolean
    second: boolean
    third: boolean
    fourth: boolean
  }>({
    first: false,
    second: false,
    third: false,
    fourth: false
  })

  const [showPlatforms, setShowPlatforms] = useState<boolean>(true)
  const [loading, setLoading] = useState(false)

  const [missingGeneralFields, setMissingGeneralFields] = useState<string[]>([])
  const [missingReleaseFields, setMissingReleaseFields] = useState<string[]>([])
  const [missingScheduleFields, setMissingScheduleFields] = useState<string[]>([])
  const [missingCopyrightFields, setMissingCopyrightFields] = useState<string[]>([])

  const [dspList, setDspList] = useState<TerritoryDsp[]>([])

  const [anyMissingInTracks, setAnyMissingInTracks] = useState<boolean>()

  const getAttribute = (attribute: string) => {
    if (Array.isArray(releaseForm.product.data.attributes[attribute])) {
      // this is added just for artist option for now. it returns an array
      return releaseForm.product.data.attributes[attribute].join(",")
    }
    return releaseForm.product.data.attributes[attribute]
  }

  const checkMissingFields = (
    propName: string,
    text: string,
    value: string | undefined,
    required: boolean,
    section?: string
  ) => {
    if (required && !value) {
      setMissingGeneralFields((prevField: string[]) => [...prevField, propName])
      if (section === "release") {
        setMissingReleaseFields((prevField: string[]) => [...prevField, text])
      } else if (section === "schedule") {
        setMissingScheduleFields((prevField: string[]) => [...prevField, text])
      } else if (section === "copyright") {
        setMissingCopyrightFields((prevField: string[]) => [...prevField, text])
      }
    }
  }

  useEffect(() => {
    checkMissingFields("name", "Album Title", releaseForm.product.data.attributes?.["name"], true, "release")
    checkMissingFields(
      "label",
      "Label",
      options.labelOptions.find((label) => label.value === releaseForm.product.data?.relationships.label?.data?.id)
        ?.label,
      true
    )
    checkMissingFields(
      "subgenre",
      "Main Genre",
      options.distributorProductSubGenreOptions.find(
        (subgenre) => subgenre.value === releaseForm.offer.data?.relationships["subgenre"]?.data?.id
      )?.label,
      true
    )
    checkMissingFields(
      "subgenre2",
      "Secondary Genre",
      options.distributorProductSubGenreOptions.find(
        (subgenre2) => subgenre2.value === releaseForm.offer.data.relationships["subgenre2"]?.data?.id
      )?.label,
      false
    )
    checkMissingFields("upc-code", "UPC", releaseForm.product.data.attributes["upc-code"], true)
    checkMissingFields(
      "product-type",
      "Product Type",
      options.productTypeOptions.find(
        (genre) => genre.value === releaseForm.product.data.relationships["product-type"]?.data?.id
      )?.label,
      true
    )
    checkMissingFields(
      "release-date",
      "Release Date",
      formatDate2(releaseForm.offer.data.attributes["release-date"]),
      true,
      "schedule"
    )
    checkMissingFields(
      "original-release-date",
      "Original Release Date",
      formatDate2(getAttribute("original-release-date")),
      false
    )
    checkMissingFields(
      "preorder-date",
      "Preorder Date",
      formatDate2(releaseForm.offer.data.attributes["preorder-date"]),
      false
    )
    checkMissingFields(
      "language",
      "Metadata Language",
      options.languageOptions.find(
        (language) => language.value === releaseForm.product.data?.relationships.language?.data?.id
      )?.label,
      true
    )
    checkMissingFields(
      "cover-url",
      "Cover Art",
      releaseForm.product.data.attributes["cover-url"]
        ? "Cover Uploaded"
        : releaseForm.product.data.attributes["cover-url"],
      true,
      "release"
    )
    checkMissingFields("booklet", "Booklet:", releaseForm.product.data.attributes["booklet"], false)
    checkMissingFields(
      "artist",
      "Main Artist",
      releaseForm.product.data.attributes["artist"]?.join(","),
      true,
      "release"
    )
    checkMissingFields("production-year", "Production Year", getAttribute("production-year"), true, "release")
    checkMissingFields("production-year", "Production Year", getAttribute("production-year"), true, "copyright")
    checkMissingFields(
      "copyright-line",
      "Copyright Holder",
      releaseForm.product.data.attributes["copyright-line"],
      true,
      "copyright"
    )
    checkMissingFields(
      "production-line",
      "Production Holder",
      releaseForm.product.data.attributes["production-line"],
      true,
      "copyright"
    )
    checkMissingFields("label-internal-reference", "Catalog Number:", getAttribute("label-internal-reference"), false)
    checkMissingFields(
      "price-code",
      "Album Price",
      options.distributorPriceCodeOptions
        .find((price) => price.value === releaseForm.offer.data?.relationships["price-code"]?.data?.id)
        ?.label.split("-")[1],
      true
    )
    checkMissingFields(
      "track-price-code",
      "Track Price",
      options.distributorTrackPriceOptions
        .find((price) => price.value === releaseForm.offer.data?.relationships["track-price-code"]?.data?.id)
        ?.label.split("-")[1],
      true
    )
  }, [releaseForm])

  useEffect(() => {
    if (releaseForm.product.data.id) {
      setLoading(true)
      releases(`/release/label_camp_product_get/?product_id=${releaseForm.product.data.id}`, "get").then((resp) => {
        setReleaseForm({ ...resp.data.result })
        setLoading(false)
      })
    }
  }, [])

  useEffect(() => {
    if (distributedRights?.length > 0) {
      return
    }

    try {
      releases(
        `/release/label_camp_product_computed_rights/?product_id=${releaseForm.product.data.id}&label_id=${releaseForm.product.data.relationships?.label?.data?.id}`,
        "get"
      ).then((resp) => {
        if (resp && resp["data"] && resp["data"].length > 0) {
          const rights = resp["data"][0]["rights"]
          // setNotDistributedRights(rights.filter((right) => right["name"] === "Not distributed"))
          setDistributedRights(
            rights.filter((right) => right["name"] !== "Not distributed").flatMap((right) => right["dsps"])
          )
        }
      })
      releases(`/release/asset_registry_dsp_list/?limit=${50}`, "get").then((resp) => {
        setDspList(mapListToCamelCase(resp.data.results))
      })
    } catch (e) {
      console.error("Error Getting Territory & Platform Rights")
      setErrorText(e)
    }
  }, [])

  const processCatalogString = (str: string, index: number, labelName: string) => {
    const userBmwCustomId = bmwCustomIdKeyword || labelName.substring(0, 2)

    let catalogNo = str.replace(/[çÇğĞıİöÖşŞüÜ]/g, (match) => turkishCharMap[match])
    catalogNo = catalogNo.replace(/[aeiouAEIOU ]/g, "").toUpperCase()
    while (catalogNo.length < 5) {
      catalogNo += "0"
    }
    catalogNo = catalogNo.slice(0, 5)

    const trackNumber = index.toString().padStart(4, "0")

    const labelId = releaseForm.product.data.relationships.label?.data?.id
    let channelId = "UCpi46yeWw2cWYM2K5lP_yCw"
    if (labelId === "208632" || labelId === "359442") {
      channelId = "UCWLXZVmRTZjqg7VBK4yPs5g"
    }

    return `${userBmwCustomId}_${catalogNo}_${trackNumber}_CHANNEL_${channelId}`
  }

  const buildBMVTrackList = () => {
    return releaseForm.tracks?.map((track, index) => {
      const product = releaseForm.product.data

      const labelName =
        options.labelOptions?.find((label) => label.value === product.relationships.label?.data?.id)?.label || ""

      const releaseName = product.attributes.name
      const releaseNameFiltered = releaseName.replace(/\(.*?\)/g, "").trim()

      const releaseGenre = options.distributorProductSubGenreOptions.find(
        (subgenre) => subgenre.value === releaseForm.offer.data?.relationships["subgenre"]?.data?.id
      )?.label

      let releaseGenreMapped = ""
      if (releaseGenre && Object.keys(state51GenreMapping).includes(releaseGenre)) {
        releaseGenreMapped = state51GenreMapping[releaseGenre]
      }

      const requestBody = {
        filename: `${track.data.id}.mp3`,
        custom_id: processCatalogString(releaseName, index + 1, labelName),
        asset_id: null,
        isrc: track.data.attributes["isrc-code"],
        add_asset_labels: `Kahuna Music|${labelName}|${releaseNameFiltered}`,
        upc: product.attributes["upc-code"],
        grid: null,
        song_title: track.data.attributes["title"],
        artist: track.data.attributes["artist"].join("|"),
        artist_isni: null,
        album: releaseName,
        genre: releaseGenreMapped,
        label: labelName,
        original_release_date: null,
        ownership: "WW",
        match_policy: "Monetize in all countries"
      }
      return Object.values(requestBody)
    })
  }

  const buildMesamMsgTrackList = () => {
    return releaseForm.tracks?.map((track, index) => {
      const releaseGenre = options.distributorProductSubGenreOptions.find(
        (subgenre) => subgenre.value === releaseForm.offer.data?.relationships["subgenre"]?.data?.id
      )?.label

      let releaseGenreMapped = ""
      if (releaseGenre && Object.keys(state51GenreMapping).includes(releaseGenre)) {
        releaseGenreMapped = state51GenreMapping[releaseGenre]
      }

      const currentDate = new Date()

      const requestBody = {
        submissionDateAndTime: formatDateTurkish(currentDate),
        trackName: track.data.attributes["title"],
        trackName2: track.data.attributes["title"],
        lyrics: track.data.attributes["lyrics"]?.split("\n")[0] || "",
        genre: releaseGenreMapped,
        duration: getDuration(track),
        artist: track.data.attributes["artist"].join(" & "),
        producerName: "", // Do Nothing
        producerCode: "", // Do Nothing
        productionYear: releaseForm.product.data.attributes["production-year"],
        audioFileExist: "YES",
        lyricsExist: "YES",
        composer1Name: "", // Filled in backend  12
        composer1Share: "", // Filled in backend
        composer2Name: "", // Filled in backend  14
        composer2Share: "", // Filled in backend
        composer3Name: "", // Filled in backend 16
        composer3Share: "", // Filled in backend
        composer4Name: "", // Filled in backend 18
        composer4Share: "", // Filled in backend
        lyricist1Name: "", // Filled in backend 20
        lyricist1Share: "", // Filled in backend
        lyricist2Name: "", // Filled in backend 22
        lyricist2Share: "", // Filled in backend
        lyricist3Name: "", // Filled in backend 24
        lyricist3Share: "", // Filled in backend
        lyricist4Name: "", // Filled in backend 26
        lyricist4Share: "", // Filled in backend
        adaptor1: "", // Do Nothing
        adaptor2: "", // Do Nothing
        adaptor3: "", // Do Nothing
        adaptor4: "", // Do Nothing
        arranger1Name: "", // Filled in backend 32
        arranger1Share: "", // Filled in backend
        arranger2Name: "", // Filled in backend 34
        arranger2Share: "", // Filled in backend
        arranger3Name: "", // Filled in backend 36
        arranger3Share: "", // Filled in backend
        editorName: "", // Filled in backend 38
        editorShare: "", // Filled in backend
        submissionDay: currentDate.getDate(),
        submissionMonth: currentDate.getMonth() + 1,
        submissionYear: currentDate.getFullYear() % 10,
        isrc: track.data.attributes["isrc-code"] //43
      }
      return Object.values(requestBody)
    })
  }

  const buildState51TrackList = () => {
    const product = releaseForm.product.data

    const labelName =
      options.labelOptions?.find((label) => label.value === product.relationships.label?.data?.id)?.label || ""

    const releaseGenre = options.distributorProductSubGenreOptions.find(
      (subgenre) => subgenre.value === releaseForm.offer.data?.relationships["subgenre"]?.data?.id
    )?.label

    let releaseGenreMapped = ""
    if (releaseGenre && Object.keys(state51GenreMapping).includes(releaseGenre)) {
      releaseGenreMapped = state51GenreMapping[releaseGenre]
    }

    const trackList = releaseForm.tracks?.map((track, index) => {
      const audioLanguage =
        options.languageOptions.find(
          (language) => language.value === track?.data.relationships["audio-language"]?.data?.id
        )?.label ||
        options.metadataLanguageOptions.find(
          (language) => language.value === releaseForm.product.data.relationships.language?.data?.id
        )?.label

      let languageMapped = "No linguistic content - zxx"
      if (audioLanguage && Object.keys(state51LanguageMapping).includes(audioLanguage)) {
        languageMapped = state51LanguageMapping[audioLanguage]
      }

      const parentalAdvisory = track.data.attributes["parental-advisory"] || false
      const clean = track.data.attributes["clean"] || false
      let explicitContent = "cleaned"
      if (!parentalAdvisory && !clean) explicitContent = "no"
      else if (parentalAdvisory && !clean) explicitContent = "yes"

      const requestBody = {
        id: `${track.data.id}`,
        "volume-number": track.data.attributes["volume-number"].toString().padStart(2, "0"),
        "track-number": track.data.attributes["track-number"].toString().padStart(2, "0"),
        isrc: track.data.attributes["isrc-code"],
        "explicit-content": explicitContent,
        title: track.data.attributes["title"],
        subtitle: track.data.attributes["subtitle"] || "",
        artist: track.data.attributes["artist"].join("|"),
        "production-line":
          track.data.attributes["production-line"] || releaseForm.product.data.attributes["production-line"],
        producer: track.data.attributes["producer"]?.join("|") || "",
        composer: track.data.attributes["composer"]?.join("|") || "",
        lyricist: track.data.attributes["lyricist"]?.join("|") || "",
        publisher: track.data.attributes["publisher"]?.join("|") || "",
        "audio-language": languageMapped
      }
      return requestBody
    })

    return {
      product: {
        "cover-url": releaseForm.product.data.attributes["full-cover-url"],
        "release-date":
          releaseForm.product.data.attributes["release-date"] || releaseForm.offer.data.attributes["release-date"],
        "label-internal-reference": releaseForm.product.data.attributes["label-internal-reference"],
        name: releaseForm.product.data.attributes["name"],
        subtitle: releaseForm.product.data.attributes["subtitle"] || "",
        artist: releaseForm.product.data.attributes["artist"].join("|"),
        "upc-code": releaseForm.product.data.attributes["upc-code"],
        "product-type-name": releaseForm.product.data.attributes["product-type-name"],
        "production-line": releaseForm.product.data.attributes["production-line"],
        "copyright-line": releaseForm.product.data.attributes["copyright-line"],
        label: labelName,
        genre: releaseGenreMapped,
        featuring: releaseForm.product.data.attributes["featuring"]?.join("|") || ""
      },
      track_list: trackList
    }
  }

  const submitForm = () => {
    const productRelationships = {}
    Object.entries(releaseForm.product.data.relationships).forEach(([key, value]) => {
      if (value["data"] && !notAllowedProductRelationships.includes(key)) {
        productRelationships[key] = { data: value["data"] }
      }
    })
    const productAttributes = {
      status: "reviewing",
      "are-rights-confirmed": true,
      "label-rights-message": null
    }

    const filteredReleaseForm = {
      data: {
        id: releaseForm.product.data.id,
        attributes: productAttributes,
        relationships: productRelationships,
        type: "products"
      },
      bmv_track_list: buildBMVTrackList(),
      state_51_form: buildState51TrackList(),
      mesam_msg_track_list: buildMesamMsgTrackList()
    }
    try {
      setLoading(true)
      releases(`/release/label_camp_product_update/`, "post", { ...filteredReleaseForm })
        .then((resp) => {
          setLoading(false)
          if (resp.data.success) {
            if (isPublishing) {
              toast.success(summaryPublishingText, successToastOptions)
            }

            navigate("/catalog")
          } else {
            setErrorText(resp.data.result)
          }
        })
        .catch((err) => {
          setErrorText(err)
          setLoading(false)
          console.error(err)
        })
    } catch (e) {
      setLoading(false)
      console.error("Error saving product")
      setErrorText(e)
    }
  }

  const renderDistributedRights = () => {
    const releaseGenre = options.distributorProductSubGenreOptions.find(
      (subgenre) => subgenre.value === releaseForm.offer.data?.relationships["subgenre"]?.data?.id
    )?.label
    let releaseGenreMapped = ""
    if (releaseGenre && Object.keys(state51GenreMapping).includes(releaseGenre)) {
      releaseGenreMapped = state51GenreMapping[releaseGenre]
    }

    const processedDspList = dspList
      .filter(
        (dsp) => dsp.label !== "Beatport" || releaseGenreMapped === "Electronic" || releaseGenreMapped === "Dance"
      )
      .map((dsp) => {
        return {
          name: dsp.label,
          image_url: dsp.image
        }
      })

    const allPlatformList = distributedRights.concat(processedDspList).sort((a, b) => a.name.localeCompare(b.name))

    return (
      <div className="grid grid-cols-2 gap-y-4 gap-x-2">
        {allPlatformList.map((computedRight) => {
          return computedRight["name"] === "Spotify" ? (
            <div>
              <img style={{ width: 105 }} src={SpotifyLogo} />
            </div>
          ) : (
            <div key={`${computedRight["name"]}`} className="grow flex flex-row gap-2 items-center">
              <div className="w-7 rounded-[4px]">
                <img src={computedRight["image_url"]} alt={`platform ${computedRight["name"]} icon`} />
              </div>
              <KSpan text={computedRight["name"]} color="#111" fontSize={14} fontWeight={500} lineHeight="16px" />
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div className="w-full flex justify-center items-center">
      {loading && <Loading />}
      <ErrorModal text={errorText} setText={setErrorText} />
      <div className="w-[616px] max-sm:w-auto flex flex-col gap-8 pb-10">
        <div className="flex flex-col gap-6">
          <div className="flex flex-row justify-between items-center">
            <KTitleSpan text="Release" fontSize={20} lineHeight="28px" color="#1F1F1F" />
            <KButton
              icon={EditIcon}
              height="36px"
              width="36px"
              padding="8px"
              background="#FFF"
              shadowDisabled
              onClick={() => {
                setSelectedNavigationTab(ReleaseRegistryPages.RELEASE)
              }}
              hoverBackground="#E7E7E7"
            />
          </div>
          <div
            className="flex flex-row p-1.5 pr-2 gap-6 rounded-[10px] items-center"
            style={{
              border: missingReleaseFields.length > 0 ? "1px solid #E52B2B" : "1px solid #E7E7E7"
            }}
          >
            <div className="w-24 h-24 rounded-[6px]">
              {/*TODO: decide if front-cover is necessary as src, in AssetRegistry Summary it is not used, but it is used in GeneralInformation */}
              <img
                src={releaseForm.product.data.attributes["cover-url"] || DefaultCoverImage}
                alt="cover"
                className="rounded-[6px]"
              />
            </div>
            <div className="flex flex-col justify-start gap-2">
              <KTitleSpan
                fontSize={24}
                lineHeight="32px"
                color="#1F1F1F"
                text={`${
                  releaseForm.product.data.attributes?.["name"]
                    ? releaseForm.product.data.attributes["name"]
                    : "Untitled Album"
                }`}
              />
              <div className="flex flex-row gap-1.5">
                <div className="p-1 flex items-center justify-center bg-[#F5F5F5] rounded-[4px] h-5">
                  <KSpan
                    text={`${
                      releaseForm.product.data.attributes["artist"]?.length > 0
                        ? releaseForm.product.data.attributes["artist"]?.join(", ")
                        : "No Artist"
                    }`}
                    color="#111"
                    fontSize={11}
                    fontWeight={500}
                    lineHeight="12px"
                    letterSpacing="0.22px"
                  />
                </div>
                <KTooltip
                  content={
                    <div className="whitespace-nowrap flex items-center justify-center">
                      <KSpan text="Production-Year" fontSize={10} fontWeight={500} color="#1F1F1F" />
                    </div>
                  }
                >
                  <div className="p-1 flex items-center justify-center bg-[#F5F5F5] rounded-[4px] h-5">
                    <KSpan
                      text={releaseForm.product.data.attributes?.["production-year"] || "-"}
                      color="#111"
                      fontSize={11}
                      fontWeight={500}
                      lineHeight="12px"
                      letterSpacing="0.22px"
                    />
                  </div>
                </KTooltip>
                <div className="p-1 flex items-center justify-center bg-[#F5F5F5] rounded-[4px] h-5">
                  <KSpan
                    text={`${releaseForm.tracks?.length || 0} SONGS`}
                    color="#111"
                    fontSize={11}
                    fontWeight={500}
                    lineHeight="12px"
                    letterSpacing="0.22px"
                  />
                </div>
              </div>
            </div>
            {missingReleaseFields.length > 0 && (
              <div className="ml-auto">
                <KTooltip
                  position="left"
                  content={
                    <div className="whitespace-nowrap flex items-center justify-center  ">
                      <KSpan
                        text={missingReleaseFields?.join(", ") || ""}
                        fontSize={11}
                        fontWeight={500}
                        color="#1F1F1F"
                      />
                    </div>
                  }
                >
                  <img src={IncompleteIcon} alt="incomplete-icon" />
                </KTooltip>
              </div>
            )}
          </div>
        </div>
        <div>
          <div className="flex flex-row justify-between items-center">
            <KTitleSpan text="Schedule" fontSize={20} lineHeight="28px" color="#1F1F1F" />
            <div className="flex flex-row gap-3">
              {missingScheduleFields.length > 0 ? (
                <div className="flex items-center">
                  <KTooltip
                    position="left"
                    content={
                      <div className="whitespace-nowrap flex items-center justify-center">
                        <KSpan
                          text={missingScheduleFields?.join(", ") || ""}
                          fontSize={11}
                          fontWeight={500}
                          color="#1F1F1F"
                        />
                      </div>
                    }
                  >
                    <img src={IncompleteIcon} alt="incomplete-icon" />
                  </KTooltip>
                </div>
              ) : (
                <div className="flex flex-row gap-2 py-2 px-2.5 bg-[#F5F5F5] rounded-[10px]">
                  <img src={CalendarIcon} alt="calendar-icon" />
                  <KSpan text={formatDate2(releaseForm.offer.data.attributes?.["release-date"])} color="#111" />
                </div>
              )}
              <img src={VectorIcon} alt="vector-icon" />
              <KButton
                icon={EditIcon}
                height="36px"
                width="36px"
                padding="8px"
                background="#FFF"
                shadowDisabled
                onClick={() => {
                  setRedirectedFromSummary(true)
                  setSelectedNavigationTab(ReleaseRegistryPages.RELEASE)
                }}
                hoverBackground="#E7E7E7"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-6">
          <div className="flex flex-row justify-between items-center">
            <KTitleSpan text="Tracks" fontSize={20} lineHeight="28px" color="#1F1F1F" />
            <KButton
              icon={EditIcon}
              height="36px"
              width="36px"
              padding="8px"
              background="#FFF"
              shadowDisabled
              onClick={() => {
                setSelectedNavigationTab(ReleaseRegistryPages.UPLOAD_TRACKS)
              }}
              hoverBackground="#E7E7E7"
            />
          </div>
          <div className="flex flex-col rounded-[10px] gap-1.5">
            {/*Check the previous Summary design, missingtrackFields are not needed in this version but check it to be sure.
            There is an missingGeneralFields state in previous design, check it if it is needed too.*/}
            {releaseForm.tracks.length > 0 &&
              releaseForm.tracks.map((track, index: number) => {
                const missingTrackFields = checkMissingTrackFields(track)
                const missingFieldsNumber = missingTrackFields ? missingTrackFields.length : 0
                if (!anyMissingInTracks && missingFieldsNumber > 0) {
                  setAnyMissingInTracks(true)
                }
                return (
                  <div
                    key={`track_${track?.data?.id}_index`}
                    className={`flex flex-row gap-2 rounded-[6px]  ${
                      missingCopyrightFields.length > 0 ? "pr-2" : "pr-0"
                    }`}
                    style={{
                      border: missingFieldsNumber > 0 ? "1px solid #E52B2B" : "none"
                    }}
                  >
                    <div className="grow">
                      <AudioPlayerProgress
                        src={track.data.attributes["preview-url"]}
                        trackName={track.data.attributes?.title || "missing title"}
                        trackDuration={
                          track.data.attributes?.length ? formatDuration(track.data.attributes?.length) : "0:00"
                        }
                        trackDurationSecond={track.data.attributes?.length}
                        trackArtist={
                          track.data?.attributes["artist"]?.length > 0
                            ? track.data?.attributes["artist"]?.join(", ")
                            : "missing artist"
                        }
                        playingAudioRefs={playingAudioRefs}
                        setPlayingAudioRefs={setPlayingAudioRefs}
                      />
                    </div>
                    {missingFieldsNumber > 0 && (
                      <div className="flex flex-row items-center">
                        <KTooltip
                          position="left"
                          content={
                            <div className="flex items-center justify-center w-[200px] ">
                              <KSpan
                                text={missingTrackFields?.join(", ") || ""}
                                fontSize={11}
                                fontWeight={500}
                                color="#1F1F1F"
                              />
                            </div>
                          }
                        >
                          <img src={IncompleteIcon} alt="incomplete-icon" />
                        </KTooltip>
                      </div>
                    )}
                  </div>
                )
              })}
          </div>
        </div>
        <div className="flex flex-col gap-6">
          <div className="flex flex-row justify-between items-center">
            <KTitleSpan text="Platform" fontSize={20} lineHeight="28px" color="#1F1F1F" />
            <KButton
              icon={showPlatforms ? ChevronDownIcon : ChevronUpIcon}
              height="36px"
              width="36px"
              padding="8px"
              background="#FFF"
              shadowDisabled
              onClick={() => {
                setShowPlatforms(!showPlatforms)
              }}
              hoverBackground="#E7E7E7"
            />
          </div>
          {showPlatforms && renderDistributedRights()}
        </div>
        <div className="flex flex-col gap-6">
          <div className="flex flex-row justify-between items-center">
            <KTitleSpan text="Copyright and Production" fontSize={20} lineHeight="28px" color="#1F1F1F" />
            <KButton
              icon={EditIcon}
              height="36px"
              width="36px"
              padding="8px"
              background="#FFF"
              shadowDisabled
              onClick={() => {
                setSelectedNavigationTab(ReleaseRegistryPages.RELEASE)
              }}
              hoverBackground="#E7E7E7"
            />
          </div>
          <div
            className={`flex flex-row gap-2 rounded-[10px] ${missingCopyrightFields.length > 0 ? "px-2" : "px-0"}`}
            style={{
              border: missingCopyrightFields.length > 0 ? "1px solid #E52B2B" : "none"
            }}
          >
            <div className="grow flex flex-col gap-1.5">
              <div className="flex flex-row gap-2.5 justify-between items-center h-11">
                <KSpan text="Copyright Holder" color="#3D3D3D" fontWeight={500} />
                <KSpan
                  text={releaseForm.product.data.attributes?.["copyright-line"] || "-"}
                  color="#3D3D3D"
                  fontWeight={500}
                />
              </div>
              <div className="flex flex-row gap-2.5 justify-between items-center h-11">
                <KSpan text="Production Holder" color="#3D3D3D" fontWeight={500} />
                <KSpan
                  text={releaseForm.product.data.attributes?.["production-line"] || "-"}
                  color="#3D3D3D"
                  fontWeight={500}
                />
              </div>
              <div className="flex flex-row gap-2.5 justify-between items-center h-11">
                <KSpan text="Production Year" color="#3D3D3D" fontWeight={500} />
                <KSpan
                  text={releaseForm.product.data.attributes?.["production-year"] || "-"}
                  color="#3D3D3D"
                  fontWeight={500}
                />
              </div>
            </div>
            {missingCopyrightFields.length > 0 && (
              <div className="flex items-center">
                <KTooltip
                  position="left"
                  content={
                    <div className="whitespace-nowrap flex items-center justify-center  ">
                      <KSpan
                        text={missingCopyrightFields?.join(", ") || ""}
                        fontSize={11}
                        fontWeight={500}
                        color="#1F1F1F"
                      />
                    </div>
                  }
                >
                  <img src={IncompleteIcon} alt="incomplete-icon" />
                </KTooltip>
              </div>
            )}
          </div>
        </div>
        <div>
          <div
            className="flex flex-row gap-3 p-3 h-[84px] items-center rounded-[10px]"
            style={{
              background: "linear-gradient(0deg, #FFF 0%, #ECFBF0 100%)",
              border: "1px solid #ECFBF0"
            }}
          >
            <div className="self-start">
              <img src={CheckGreenIcon} alt="check-green-icon" />
            </div>
            <div className="flex flex-col items-between gap-3">
              <KTitleSpan text="Geographic availability" color="#1F1F1F" fontSize={20} lineHeight="28px" />
              <KSpan text="Your song will be released worldwide." color="#3D3D3D" fontWeight={500} />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <div className="flex flex-row gap-2">
            <div className="pt-1">
              <KInput
                value={"checkTerms"}
                onChange={() => {
                  setCheckedBoxes({ ...checkedBoxes, first: !checkedBoxes.first })
                }}
                type={"checkbox"}
                checked={checkedBoxes.first}
                shadowDisabled={true}
                width={16}
                height={16}
                accentColor={"black"}
                padding="0px"
                borderRadius={10}
              />
            </div>
            <KSpan
              text={`I understand my release may be rejected from stores if I don't correctly label tracks as explicit that contain swear words or obscenities.`}
              color="#1F1F1F"
              lineHeight="20px"
              fontWeight={checkedBoxes.first ? 500 : 400}
            />
          </div>
          <div className="flex flex-row gap-2 items-start">
            <KInput
              value={"checkTerms"}
              onChange={() => {
                setCheckedBoxes({ ...checkedBoxes, second: !checkedBoxes.second })
              }}
              type={"checkbox"}
              checked={checkedBoxes.second}
              shadowDisabled={true}
              width={16}
              height={16}
              accentColor={"black"}
              padding="0px"
              borderRadius={10}
            />
            <KSpan
              text={`I'm authorised to distribute this music to stores and territories I select.`}
              color="#1F1F1F"
              lineHeight="20px"
              fontWeight={checkedBoxes.second ? 500 : 400}
            />
          </div>
          <div className="flex flex-row gap-2 items-start">
            <div className="pt-1">
              <KInput
                value={"checkTerms"}
                onChange={() => {
                  setCheckedBoxes({ ...checkedBoxes, third: !checkedBoxes.third })
                }}
                type={"checkbox"}
                checked={checkedBoxes.third}
                shadowDisabled={true}
                width={16}
                height={16}
                accentColor={"black"}
                padding="0px"
                borderRadius={10}
              />
            </div>
            <KSpan
              text="I'm not using any other artist's name in my name, song titles, or album title, without their approval."
              color="#1F1F1F"
              lineHeight="20px"
              fontWeight={checkedBoxes.third ? 500 : 400}
            />
          </div>
          <div className="flex flex-row gap-2 items-start">
            <div className="pt-1">
              <KInput
                value={"checkTerms"}
                onChange={() => {
                  setCheckedBoxes({ ...checkedBoxes, fourth: !checkedBoxes.fourth })
                }}
                type={"checkbox"}
                checked={checkedBoxes.fourth}
                shadowDisabled={true}
                width={16}
                height={16}
                accentColor={"black"}
                padding="0px"
                borderRadius={10}
              />
            </div>
            <KSpan
              text="I confirm that my genres are correctly labeled, and I understand that mislabeling could result in my release being rejected from stores"
              color="#1F1F1F"
              lineHeight="20px"
              fontWeight={checkedBoxes.fourth ? 500 : 400}
            />
          </div>
        </div>
        <div>
          <div className="px-[14px] pt-[14px] pb-4 flex flex-row justify-between items-start gap-3 bg-[#FFF7EA] rounded-[10px]">
            <div className="shrink-0">
              <img src={AlertIcon} />
            </div>
            <div className="opacity-80 inline-flex">
              <KSpan
                text={`Please note that answering incorrectly to any of the following questions may result in delays to your music being sent to retailers.`}
                color="#4D360E"
              />
            </div>
          </div>
        </div>
        <div>
          <KButton
            text="Confirm Release"
            onClick={() => {
              submitForm()
            }}
            background="#000"
            textColor="#FFF"
            disabled={
              !checkedBoxes.first ||
              !checkedBoxes.second ||
              !checkedBoxes.third ||
              !checkedBoxes.fourth ||
              missingGeneralFields.length > 0 ||
              anyMissingInTracks
            }
          />
        </div>
      </div>
    </div>
  )
}

export default Summary
