import React, { FC, useState } from "react"

import { KSpan } from "kahuna-base-react-components"
import { Release, SelectOption, User } from "../../../../../types"
import { NewReleaseActions, TakedownActionTabs } from "../../../../../actions/types"
import { lang } from "../../../../../constants/languages"
import { toast } from "react-toastify"
import { loadingToastOptions } from "../../../../../constants"

type NavigationTabProps = {
  selectedPage: number
  selectedTab: number
  setSelectedTab: (value: number) => void
  releaseList: Release[]
  user: User
  selectedArtist?: SelectOption
}

const NavigationTab: FC<NavigationTabProps> = (props) => {
  const { selectedPage, selectedTab, setSelectedTab } = props

  const renderFinishRelease = () => {
    const styles = { borderRadius: 10, width: "fit-content", padding: "10px 16px" }
    const className = "flex gap-2 cursor-pointer"

    return (
      <>
        <div className={className} style={{ ...styles, background: "#F3F3F3" }}>
          <img src="/catalog_icons/music-list.svg" />
          <KSpan
            text={
              props.releaseList.length > 0
                ? lang.catalog.new_release.finish_release
                : lang.catalog.new_release.new_release
            }
            fontSize={14}
            color="#000"
          />
        </div>
      </>
    )
  }

  const renderTakedownTabs = () => {
    const styles = { borderRadius: 10, width: "fit-content", padding: "10px 16px" }
    const className = "mt-2 flex gap-2 cursor-pointer"

    return (
      <>
        <div
          className={className}
          style={{ ...styles, background: selectedTab === TakedownActionTabs.SPOTIFY_ARTIST ? "#F3F3F3" : "#FFF" }}
          onClick={() => setSelectedTab(TakedownActionTabs.SPOTIFY_ARTIST)}
        >
          {props.selectedArtist ? (
            <img src="/catalog_icons/check-circle.svg" />
          ) : (
            <img src="/account_icons/user-search.svg" />
          )}

          <KSpan text={lang.catalog.new_release.select_artist} fontSize={14} color="#000" />
        </div>
        <div
          className={className}
          style={{ ...styles, background: selectedTab === TakedownActionTabs.SELECT_ALBUM ? "#F3F3F3" : "#FFF" }}
          onClick={() => {
            if (!props.selectedArtist) {
              toast.success(lang.catalog.new_release.artist_select_warning, loadingToastOptions)
              return
            }
            setSelectedTab(TakedownActionTabs.SELECT_ALBUM)
          }}
        >
          <img src="/account_icons/music-album-dark.svg" />
          <KSpan text={lang.catalog.new_release.select_album} fontSize={14} color="#000" />
        </div>
      </>
    )
  }

  return (
    <div className="grid">
      <div style={{ marginLeft: 16, marginBottom: 16 }}>
        <KSpan text={lang.catalog.new_release.steps} fontSize={11} />
      </div>
      {selectedPage === NewReleaseActions.FINISH_RELEASE && renderFinishRelease()}
      {selectedPage === NewReleaseActions.TAKEDOWN && renderTakedownTabs()}
    </div>
  )
}

export default NavigationTab
