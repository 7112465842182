import { SortParams, TableProps } from "../../../types"
import React, { FC, useEffect, useRef, useState } from "react"
import { tableCellClasses, tableTitleClasses } from "../../../constants"
import { Filter, FilterOutline } from "heroicons-react"
import { isEqual } from "lodash"
import { KSpan, KTitleSpan } from "kahuna-base-react-components"
import Pagination from "../../ui/table/Pagination"
import { useNavigate } from "react-router-dom"
import ReleaseComponent from "./ReleaseComponent"
import { hashids } from "../../../utility"
import NotFound from "../../NotFound"
import { lang } from "../../../constants/languages"

const CatalogTable: FC<TableProps> = (props) => {
  const {
    topRightElement,
    className,
    size = "lg",
    title,
    columns,
    data,
    minRowCount = 10,
    rowHeightInPx,
    count = 0,
    limit = 10,
    pagination,
    onClickPage,
    loading,
    setTableAPI,
    hidden,
    additionalFilters,
    inactiveRows
  } = props

  interface Data {
    quarter: string
    revenue: number
    total_payment: number
    currency: string
    index: number
    payment_day?: string[]
  }

  const [currentPage, setCurrentPage] = useState(1)
  const [sortParams, setSortParams] = useState<SortParams>(new SortParams())
  const [filtersShown, setFiltersShown] = useState(false)
  const [filters, setFilters] = useState<{ [key: string]: string | number | boolean }>({})
  const [filteredData, setFilteredData] = useState<Data[]>(data)
  const additionalFiltersRef = useRef(additionalFilters)
  const navigate = useNavigate()

  const onClickPagination = (pageNumber) => {
    if (currentPage !== pageNumber) {
      if (onClickPage) onClickPage(pageNumber, filters, sortParams.field, sortParams.direction)
      setCurrentPage(pageNumber)
    }
  }

  useEffect(() => {
    if (setTableAPI) setTableAPI({ currentPage, setCurrentPage })
  }, [currentPage, count])

  useEffect(() => {
    if (!isEqual(additionalFiltersRef.current, additionalFilters)) {
      const newFilters = { ...filters, ...additionalFilters }
      setFilters(newFilters)
      additionalFiltersRef.current = additionalFilters
    }
  }, [additionalFilters])

  if (hidden) return null

  return (
    <div className={`${className} pb-4`}>
      <div className="flex flex-row justify-between items-center">
        <div className={`flex font-medium font-sans text-gray-900   ${tableTitleClasses[size]}`}>
          <KTitleSpan text={title!.toString()} fontSize={28} fontWeight={500} lineHeight="40px" color="#111" />
          {columns.some((col) => col.filter) && (
            <span onClick={() => setFiltersShown(!filtersShown)}>
              {filtersShown ? (
                <Filter className={"inline h-3 mb-[2px] cursor-pointer"} />
              ) : (
                <FilterOutline className={"inline h-3 mb-[2px] cursor-pointer"} />
              )}
            </span>
          )}
        </div>

        {topRightElement}
      </div>
      <div className="mt-4 bg-white elev-b-sm rounded-lg w-full overflow-hidden !shadow-none pb-8">
        <ul className="px-[3px]">
          {!loading &&
            data?.map((row, i) => {
              return (
                <li key={`data-row-${i}`} onClick={() => navigate(hashids.encode(row.id?.toString() || ""))}>
                  <ReleaseComponent release={row} overrideBorder={false} fullScreen={true} />
                </li>
              )
            })}
          {!loading && data.length === 0 && (
            <div className="flex justify-center mt-12">
              <NotFound
                title={lang.not_found.catalog.title}
                description={lang.not_found.catalog.desc}
                imageWidth={225}
              />
            </div>
          )}
        </ul>
      </div>
      <Pagination
        hidden={!pagination || !data || data.length === 0 || limit >= count}
        size={size}
        pageCount={Math.ceil(count / limit)}
        currentPage={currentPage}
        onClickPage={onClickPagination}
      />
    </div>
  )
}

export default CatalogTable
