//@ts-ignore
import AdminIcon from "../../../ui/img/navigate/shield-keyhole.svg"
//@ts-ignore
import TeamLineIcon from "../../../ui/img/navigate/team-line.svg"
//@ts-ignore
import FileChartLineIcon from "../../../ui/img/navigate/file-chart-line.svg"
//@ts-ignore
import ComputerLineIcon from "../../../ui/img/navigate/computer-line.svg"
//@ts-ignore
import BatchIdIcon from "../../../ui/img/navigate/batch-id.svg"
//@ts-ignore
import UpcScanIcon from "../../../ui/img/navigate/upc-scan.svg"
//@ts-ignore
import DspIcon from "../../../ui/img/navigate/dsp.svg"
import { adminRoleList, allRoleList, regularRoleList, USER_ROLE } from "../../../../constants"
import { lang } from "../../../../constants/languages"

const navElements = [
  {
    name: lang.navigation.overview,
    iconPath: "/navigation_icons/overview.svg",
    href: "/overview",
    bgColor: "#ffffff",
    permittedRoles: regularRoleList,
    bRadius: 2,
    type: "link"
  },
  {
    name: lang.navigation.releases,
    iconPath: "/navigation_icons/music.svg",
    href: "/catalog",
    bgColor: "#ffffff",
    permittedRoles: regularRoleList,
    bRadius: 2,
    type: "link"
  },
  {
    name: lang.navigation.reports,
    iconPath: "/navigation_icons/sticky-note.svg",
    href: "/reports",
    bgColor: "#ffffff",
    permittedRoles: regularRoleList,
    bRadius: 10,
    type: "link"
  },
  {
    name: lang.navigation.analytics,
    iconPath: "/navigation_icons/analytics.svg",
    href: "/analytics",
    bgColor: "#ffffff",
    permittedRoles: regularRoleList,
    bRadius: 2,
    type: "link"
  },
  {
    name: lang.navigation.artist_space,
    iconPath: "/navigation_icons/earth.svg",
    href: "/artist-space",
    bgColor: "#ffffff",
    permittedRoles: regularRoleList,
    bRadius: 10,
    type: "link"
  },
  {
    name: lang.navigation.affiliate,
    iconPath: "/navigation_icons/affiliate.svg",
    href: "/affiliate",
    bgColor: "#ffffff",
    attributeToCheck: "affiliateCount",
    permittedRoles: allRoleList,
    bRadius: 10,
    type: "link"
  },
  {
    name: "Admin",
    iconPath: AdminIcon,
    bgColor: "#ffffff",
    href: "/admin",
    permittedRoles: adminRoleList,
    bRadius: 10,
    type: "accordion",
    sublinks: [
      {
        name: "User Asset",
        href: "/user-asset",
        iconPath: TeamLineIcon,
        permittedRoles: [USER_ROLE.SuperAdmin, USER_ROLE.Admin]
      },
      {
        name: "Asset Registry (Releases)",
        href: "/catalog",
        iconPath: "/navigation_icons/music.svg",
        permittedRoles: adminRoleList
      },
      {
        name: "Royalty Reports",
        href: "/royalty-reports",
        iconPath: "/navigation_icons/chart.svg",
        permittedRoles: [USER_ROLE.SuperAdmin]
      },
      {
        name: "Reports Approval",
        href: "/reports-approval",
        iconPath: FileChartLineIcon,
        permittedRoles: [USER_ROLE.SuperAdmin]
      },
      {
        name: "Scheduler Logs",
        href: "/scheduler-logs",
        iconPath: ComputerLineIcon,
        permittedRoles: [USER_ROLE.SuperAdmin]
      },
      {
        name: "State51 Batch Ids",
        href: "/state51-batch-ids",
        iconPath: BatchIdIcon,
        permittedRoles: [USER_ROLE.SuperAdmin, USER_ROLE.Admin]
      },
      {
        name: "Release Upc Codes",
        href: "/upc-codes",
        iconPath: UpcScanIcon,
        permittedRoles: [USER_ROLE.SuperAdmin, USER_ROLE.Admin]
      },
      {
        name: "Track Isrc Codes",
        href: "/isrc-codes",
        iconPath: UpcScanIcon,
        permittedRoles: [USER_ROLE.SuperAdmin, USER_ROLE.Admin]
      },
      {
        name: "Territory Dsp List",
        href: "/territory-dsp",
        iconPath: DspIcon,
        permittedRoles: [USER_ROLE.SuperAdmin]
      },
      {
        name: "Registrations",
        href: "/registrations",
        iconPath: TeamLineIcon,
        permittedRoles: adminRoleList
      }
    ]
  }
]

export default navElements
