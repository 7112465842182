import { lang } from "../../../../constants/languages"
import { ChangePasswordFormValues } from "../../../../types"
import { toast } from "react-toastify"

const changePasswordValidator = (formValues: ChangePasswordFormValues) => {
  const errors: any = {}

  if (!formValues.currentPassword) {
    errors.currentPassword = lang.account.profile.change_password_form.error.current_password_blank
    toast.error(lang.account.profile.change_password_form.error.current_password_blank)
  }
  if (!formValues.newPassword || !formValues.newPasswordAgain) {
    if (!formValues.newPassword) {
      errors.newPassword = lang.account.profile.change_password_form.error.new_password_blank
      toast.error(lang.account.profile.change_password_form.error.new_password_blank)
    }
    if (!formValues.newPasswordAgain) {
      errors.repeatedPassword = lang.account.profile.change_password_form.error.repeated_password_blank
      toast.error(lang.account.profile.change_password_form.error.repeated_password_blank)
    }
  } else if (formValues.newPasswordAgain !== formValues.newPassword) {
    errors.repeatedPassword = lang.account.profile.change_password_form.error.no_match
    toast.error(lang.account.profile.change_password_form.error.no_match)
  }

  return errors
}

export default changePasswordValidator
