import { KButton, KInput, KSpan, KTitleSpan } from "kahuna-base-react-components"
//@ts-ignore
import ArrowLeftIcon from "../../../../assets/start_your_journey_icons/arrow-left.svg"
import React, { useEffect, useState } from "react"
import { saveRegistration, StartYourJourneyPages, Details } from "../StartYourJourney"
import { initialHearingSources } from "../../../../constants"
import { lang } from "../../../../constants/languages"
import { SelectOption } from "../../../../types"

const HowHearUsPage = (props: {
  details: Details
  setDetails: (details: Details) => void
  hearingSource: any
  setHearingSource: (source: any) => void
  completedSteps: { first: boolean; second: boolean; third: boolean; fourth: boolean }
  setCompletedSteps: (steps: { first: boolean; second: boolean; third: boolean; fourth: boolean }) => void
  selectedPage: number
  setSelectedPage: (page: number) => void
}) => {
  const [selectionDetails, setSelectionDetails] = useState<{
    selected: boolean
    hasSuboption: boolean
    selectedKey: string
    selectedValue: string
    subOptionSelected: boolean
    selectedSubOptionKey: string
    selectedSubOptionValue: string
  }>({
    selected: false,
    hasSuboption: false,
    selectedKey: "",
    selectedValue: "",
    subOptionSelected: false,
    selectedSubOptionKey: "",
    selectedSubOptionValue: ""
  })

  const handleOptionCheck = (key: string) => {
    const selected = props.hearingSource[key].selected || false
    props.setHearingSource({
      ...initialHearingSources,
      [key]: { ...initialHearingSources[key], selected: !selected }
    })

    if (selected) {
      setSelectionDetails({
        selectedKey: "",
        hasSuboption: false,
        selected: false,
        selectedValue: "",
        selectedSubOptionKey: "",
        subOptionSelected: false,
        selectedSubOptionValue: ""
      })
    } else {
      setSelectionDetails({
        ...selectionDetails,
        selectedKey: key,
        selectedValue: props.hearingSource[key].label,
        hasSuboption: props.hearingSource[key]?.suboptions,
        selected: true
      })
    }
  }

  const handleSuboptionCheck = (subKey:string) => {
    if (props.hearingSource[selectionDetails.selectedKey].suboptions[subKey].selected) {
      props.setHearingSource({
        ...initialHearingSources,
        [selectionDetails.selectedKey]: {
          ...initialHearingSources[selectionDetails.selectedKey],
          selected: true
        }
      })
      setSelectionDetails({
        ...selectionDetails,
        selectedSubOptionKey: "",
        subOptionSelected: false,
        selectedSubOptionValue: ""
      })
    } else {
      props.setHearingSource({
        ...initialHearingSources,
        [selectionDetails.selectedKey]: {
          ...initialHearingSources[selectionDetails.selectedKey],
          selected: true,
          suboptions: {
            ...initialHearingSources[selectionDetails.selectedKey].suboptions,
            [subKey]: {
              ...initialHearingSources[selectionDetails.selectedKey].suboptions[subKey],
              selected: true
            }
          }
        }
      })
      setSelectionDetails({
        ...selectionDetails,
        selectedSubOptionKey: subKey,
        subOptionSelected: true,
        selectedSubOptionValue: props.hearingSource[selectionDetails.selectedKey].suboptions[subKey].label
      })
    }
  }

  return (
    <div className="flex flex-row gap-6 items-start">
      {selectionDetails.selected && selectionDetails.hasSuboption && (
        <div className="pt-7">
          <KButton
            icon={ArrowLeftIcon}
            height="36px"
            width="36px"
            borderRadius={10}
            background="#F7F7F7"
            padding="8px"
            shadowDisabled
            onClick={() => {
              setSelectionDetails({
                ...selectionDetails,
                selected: false,
                hasSuboption: false,
                selectedKey: "",
                selectedValue: "",
                selectedSubOptionKey: "",
                selectedSubOptionValue: ""
              })
              props.setHearingSource({ ...initialHearingSources })
            }}
            hoverBackground="#F3F3F3"
          />
        </div>
      )}
      <div className="w-full flex flex-col gap-6 p-4">
        <div className="grow flex flex-col">
          <div className={"pt-2"}>
            <KTitleSpan
              text={lang.start_your_journey.how_hear_us_page.title}
              lineHeight={"40px"}
              fontSize={32}
              fontWeight={500}
              letterSpacing="-0.4px"
              color="#000"
            />
          </div>
          <div className={"mt-3"}>
            <KSpan
              text={lang.start_your_journey.how_hear_us_page.desc}
            />
          </div>
        </div>
        <div className="flex flex-col w-full gap-1.5">
          {((selectionDetails.selected && !selectionDetails.hasSuboption) || !selectionDetails.selected) &&
            Object.keys(props.hearingSource).map((key, index) => {
              return (
                <div className="flex flex-row gap-2 items-center" key={`${key}-${index}`}>
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      height={20}
                      checked={props.hearingSource[key].selected}
                      onChange={() => {
                        handleOptionCheck(key)
                      }}
                      style={{
                        accentColor: "#000"
                      }}
                    />
                  </div>{" "}
                  <div>
                    {props.hearingSource[key].label === lang.start_your_journey.how_hear_us_page.other &&
                    props.hearingSource[key].selected ? (
                      <KInput
                        value={props.hearingSource[key].value}
                        placeholder={lang.start_your_journey.how_hear_us_page.other}
                        onChange={(text: string) => {
                          props.setHearingSource({
                            ...props.hearingSource,
                            [key]: {
                              ...props.hearingSource[key],
                              value: text
                            }
                          })
                          setSelectionDetails({ ...selectionDetails, selectedValue: text })
                        }}
                      />
                    ) : (
                      <KSpan text={props.hearingSource[key].label} color="#000" />
                    )}
                  </div>
                </div>
              )
            })}
          {selectionDetails.selected &&
            selectionDetails.hasSuboption &&
            Object.keys(props.hearingSource[selectionDetails.selectedKey].suboptions).map((subKey, i) => {
              return (
                <div className="flex flex-row gap-2 items-center" key={`${subKey}-${i}`}>
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      height={20}
                      checked={props.hearingSource[selectionDetails.selectedKey].suboptions[subKey].selected}
                      onChange={() => {
                        handleSuboptionCheck(subKey)
                      } }
                      style={{
                        accentColor: "#000"
                      }}
                    />
                  </div>{" "}
                  <div>
                    {props.hearingSource[selectionDetails.selectedKey].suboptions[subKey].label === lang.start_your_journey.how_hear_us_page.other &&
                    props.hearingSource[selectionDetails.selectedKey].suboptions[subKey].selected ? (
                      <KInput
                        placeholder={lang.start_your_journey.how_hear_us_page.other}
                        onChange={(text) => {
                          props.setHearingSource({
                            ...props.hearingSource,
                            [selectionDetails.selectedKey]: {
                              ...props.hearingSource[selectionDetails.selectedKey],
                              suboptions: {
                                ...props.hearingSource[selectionDetails.selectedKey].suboptions,
                                [subKey]: {
                                  ...props.hearingSource[selectionDetails.selectedKey].suboptions[subKey],
                                  value: text
                                }
                              }
                            }
                          })
                          setSelectionDetails({
                            ...selectionDetails,
                            selectedSubOptionValue: text
                          })
                        }}
                        value={props.hearingSource[selectionDetails.selectedKey].suboptions[subKey].value}
                      />
                    ) : (
                      <KSpan
                        text={props.hearingSource[selectionDetails.selectedKey].suboptions[subKey].label}
                        color="#000"
                      />
                    )}
                  </div>
                </div>
              )
            })}
        </div>
        {selectionDetails.selected &&
          (!selectionDetails.hasSuboption || (selectionDetails.hasSuboption && selectionDetails.subOptionSelected)) &&
          !(
            (props.hearingSource[selectionDetails?.selectedKey]?.label === lang.start_your_journey.how_hear_us_page.other &&
              props.hearingSource[selectionDetails?.selectedKey]?.value === "") ||
            (props.hearingSource[selectionDetails?.selectedKey]?.suboptions?.[selectionDetails?.selectedSubOptionKey]
              ?.label === lang.start_your_journey.how_hear_us_page.other &&
              props.hearingSource[selectionDetails?.selectedKey]?.suboptions?.[selectionDetails?.selectedSubOptionKey]
                ?.value === "")
          ) && (
            <KButton
              text={lang.common.submit}
              onClick={() => {
                const sourceText = selectionDetails?.selectedSubOptionValue
                  ? `${selectionDetails?.selectedValue}: ${selectionDetails?.selectedSubOptionValue}`
                  : `${selectionDetails?.selectedValue}`

                props.setDetails({ ...props.details, hearingSource: sourceText })
                props.setCompletedSteps({...props.completedSteps, third: true })
                if (props.details.exceedsListenerLimit) {
                  props.setSelectedPage(StartYourJourneyPages.CALENDLY_PAGE)
                } else {
                  props.setSelectedPage(StartYourJourneyPages.THANK_YOU)
                  const artistsName = props.details.artist.map((artist:SelectOption) => artist.label).join(", ")
                  saveRegistration(
                    "",
                    "",
                    "",
                    "not_eligible",
                    "",
                    new Date().toISOString(),
                    props.details.contactName,
                    props.details.country,
                    props.details.email,
                    props.details.phoneNumber,
                    props.details?.role?.label || "",
                    artistsName,
                    sourceText
                  )
                }

              }}
              background="#111"
              textColor="#FFF"
            />
          )}
      </div>
    </div>
  )
}

export default HowHearUsPage
