// noinspection JSUnusedGlobalSymbols

import {
  ChangeEventHandler,
  CSSProperties,
  KeyboardEventHandler,
  MouseEventHandler,
  MutableRefObject,
  ReactFragment,
  ReactNode
} from "react"
import { PathRouteProps } from "react-router"
import { RootState } from "../store"
import { ANALYSIS_FILTER, INVITATION_STATUS, RELEASE_FILTER, TABLE_COLUMN_TYPE, USER_ROLE } from "../constants"
import { FieldInputProps, FieldMetaState, FieldRenderProps } from "react-final-form"
import { KSelectOption } from "kahuna-base-react-components/dist/components/KDropdown/KDropdown"
import { Method } from "axios"

export type LogoProps = {
  colorClass: string
}

export type InputProps = {
  id?: string
  type?: string
  label?: string
  className?: string
  inputClass?: string
  placeholder?: string
  autoComplete?: string
  value?: string | ReadonlyArray<string> | number
  onChange?: ChangeEventHandler<HTMLInputElement>
  onKeyPress?: KeyboardEventHandler<HTMLInputElement>
  checked?: boolean
  maxLength?: number
  disabled?: boolean
  pattern?: string
  containerStyle?: CSSProperties
  onClick?: MouseEventHandler<HTMLInputElement>
}

export type ToggleProps = {
  id?: string
  name?: string
  label?: string
  className?: string
  inputClass?: string
  labelClass?: string
  value?: string | ReadonlyArray<string> | number | boolean
  onChange?: ChangeEventHandler<HTMLInputElement>
  onKeyPress?: KeyboardEventHandler<HTMLInputElement>
  checked?: boolean
  disabled?: boolean
}

export type ButtonProps = {
  type?: "link" | "button"
  size?: "sm" | "md" | "lg"
  to?: string
  text?: string | ReactFragment
  className?: string
  onClick?: MouseEventHandler<HTMLButtonElement>
  light?: boolean
  disabled?: boolean
  buttonType?: "button" | "submit"
}

export interface FormValues {}

export class PaginatedData<DataType> {
  results: DataType[] = []
  count: number | undefined = undefined
  next = ""
  previous = ""
}

export abstract class FormProps<FormType> {
  onSubmit!: (formValues: FormValues) => void
  validate?: (formValues: FormType) => void
  onDelete?: (id: number) => void
  initialValues: FormType
  editable? = false
}

export class LoginFormValues implements FormValues {
  username?: string
  password?: string
  email?: string
  ipAddress?: string
  code?: string
}

export class FormPageProps<FormType extends Object> {
  onSubmit!: (formValues: FormType, callback?: Function) => void
  initialValues?: FormType
}

export class LoginPageProps {
  login!: (formValues: LoginFormValues, callback?: () => void) => void
  emailValidation: (
    form: LoginFormValues,
    userCheck: boolean,
    useCredentials: boolean,
    subject: string,
    callback?: (overideCodeValidation: boolean) => void
  ) => void
  validateCode: (form: LoginFormValues, callback?: () => void) => void
}

export class ForgotPasswordFormValues implements FormValues {
  email? = ""
  ipAddress? = ""
}

export class ResetPasswordFormValues implements FormValues {
  email? = ""
  code? = ""
  password? = ""
  repeatedPassword? = ""
}

export type ChangePasswordFormProps = {
  username: string
  changePassword: (formValues: ChangePasswordFormValues) => void
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export class ChangePasswordFormValues {
  username = ""
  currentPassword = ""
  newPassword = ""
  newPasswordAgain = ""
}

export type RootProps = {
  children?: ReactNode
  initialState?: RootState
}

export class InputRenderProps implements FieldRenderProps<any> {
  input!: FieldInputProps<any>
  meta!: FieldMetaState<any>
  placeholder?: string
  className?: string
  inputClass?: string
  type?: string
  label?: string
  inlineElement?: ReactFragment
}

export class ToggleRenderProps implements FieldRenderProps<any> {
  input!: FieldInputProps<any>
  meta!: FieldMetaState<any>
  inputClass?: string
  labelClass?: string
  className?: string
  label?: string
}

export class SearchSelectRenderProps implements FieldRenderProps<any> {
  input!: FieldInputProps<any>
  meta!: FieldMetaState<any>
  placeholder?: string
  className?: string
  label?: string
  loading?: boolean
  multiple?: boolean
  onSelect?: ChangeEventHandler<HTMLInputElement>
}

export type LoginLayoutProps = {
  title?: string
  children?: ReactNode
}

export type CardProps = {
  className?: string
}

export type HeaderNavigationItem = {
  name: string
  href?: string
  current: () => boolean
  permittedRoles?: USER_ROLE[]
  type: "link" | "dropdown"
  items?: ReactNode[]
}

export type HeaderDropDownItem = {
  link: ReactNode
  permittedRoles: USER_ROLE[]
}

export type DropdownMenuProps = {
  items: HeaderDropDownItem[]
  userRole: number
}

export type AccountNavigationItemProps = {
  path: string
  icon?: ReactNode
  title: string
  description: string
}

export type AccordionProps = {
  title: string | ReactFragment
  content: string | ReactFragment
  alwaysOn?: boolean
}

export type ArrowProps = {
  rotate?: number
}

export interface RouteProps extends PathRouteProps {
  private: boolean
  redirect?: string
  permittedRoles?: USER_ROLE[]
  overrideDefaultRoute?: boolean
}

export type PrivateRouteProps = {
  private: boolean
  loggedIn: boolean
  redirect?: string
  userRole: number
  permittedRoles?: USER_ROLE[]
  overrideDefaultRoute?: boolean
}

export interface Action {
  type: string
  payload: any
}

export class User {
  id: number | undefined = undefined
  firstName = ""
  lastName = ""
  email = ""
  username = ""
  isSuperuser = false
  photo = ""
  preferredCurrency = "TRY"
  roleId = 0
  permissionUser: any[]
  affiliateUserId: number | undefined = undefined
  affiliateCommission: number | undefined = undefined
  appliedAffiliateUser: User | undefined = undefined
  role?: { id: number; name: string }
  productionHolder: string
  copyrightHolder: string
  lcUsername: string
  affiliateCount: number = 0
  userExpense?: UserExpense
  bmwCustomId: string
  bio = ""
  stageName = ""
  userCredit?: UserCredit
  validateEmail = true
}

export type SaveActionResult<T> = {
  operation: "created" | "updated"
  model: T
}

export class AuthUser extends User {
  token: string
}

export type AuthState = {
  loggedIn: boolean
  user: User
  token?: string | null
}

export type PublicState = {
  invitationStatus?: InvitationStatus
}

export type ProfileMenuProps = {
  logout: (callback: () => void) => void
  loggedIn: boolean
  user: User
}

export type NavigationProps = {
  user: User
}

export class TableColumn {
  header? = ""
  headerStyle?: CSSProperties
  cellStyle?: CSSProperties
  type? = TABLE_COLUMN_TYPE.TEXT
  attribute? = ""
  datePattern? = ""
  dateParsePattern? = ""
  constType?: any
  render?: (row: any) => ReactNode
  sortDirection?: "asc" | "desc"
  sortable?: boolean
  sortFilterField? = ""
  filter?: boolean
}

export type TableProps = {
  hidden?: boolean
  title: ReactNode | string
  columns: TableColumn[]
  data: any[]
  className?: string
  minRowCount?: number
  rowHeightInPx?: number
  size?: "sm" | "md" | "lg"
  pagination?: boolean
  count?: number
  limit?: number
  onClickPage?: (
    pageNumber: number,
    filters: { [key: string]: string | number | boolean },
    sortField?: string,
    sortDirection?: "asc" | "desc"
  ) => void
  topRightElement?: ReactNode
  onSelectRow?: (index: number) => void
  loading?: boolean
  setTableAPI?: (tableAPI: TableAPI) => void
  onSortOrFilterChange?: (
    filters: { [key: string]: string | number | boolean },
    sortField: string,
    sortDirection?: "asc" | "desc",
    debounce?: boolean
  ) => Promise<void>
  additionalFilters?: any
  inactiveRows?: any[]
  isAdmin?: boolean
  selectedUserId?: number
  approveDisabled?: boolean
  platform?: string
}

export class SortParams {
  field = ""
  direction?: "asc" | "desc"
}

export type TableAPI = {
  currentPage: number
  setCurrentPage: (page: number) => void
}

export class YoutubeChannel {
  id: number | undefined = undefined
  channelId = ""
  name = ""
}

export class YoutubeVideo {
  id: number | undefined = undefined
  channel: number | undefined = undefined
  assetId: ""
  channelDetail = new YoutubeChannel()
  customId = ""
  isVerified = false
  isrc = ""
  title = ""
  track: number | undefined = undefined
  trackDetail = new Track()
  trackIsrc = ""
  videoId = ""
  mainOwnerShare = new MainOwnerShareData()
}

export class MainOwnerShareData {
  user = ""
  share: number | undefined = undefined
}

export class Track {
  id: number | undefined = undefined
  release: number | undefined = undefined
  artists: number[] = []

  artistsList: Artist[] = []
  releaseDetail = new Release()
  mainOwnerShare = new MainOwnerShareData()
  shareData?: { length: number; roles: string[]; share: number }
  shareHolders?: { id: number; roleList: UserTrackShareRole[]; share: number; userId: number; userName: string }[] = []

  title = ""
  isrc = ""
  iswc = ""
  audioDuration: ""
  audioFileUrl: ""
  audioLanguage: ""
  number: ""
  parentalAdvisory: false
  recordingPlace: ""
  genre = ""
  copyright = ""
  metadataLanguage = ""

  producer = ""
  author = ""
  composer = ""
  arranger = ""
  contributor = ""
  remixer = ""
  featuring = ""
  lyricist = ""
  lyrics = ""
}

export class Release {
  id: number | undefined = undefined
  label: number | undefined = undefined
  publisher: number | undefined = undefined
  artists: number[] = []
  title = ""
  releaseDate = ""
  artistsList: Artist[] = []
  labelDetail = new Label()
  publisherDetail = new Publisher()
  coverFileUrl = ""
  upc = ""
  platforms = ""
  status = ""
  type = ""
  production = ""
  labelCamp: boolean = false
  productionYear = ""
  catalogNumber = ""
  tracksList?: Track[] = []
  productId?: string
  genre: string = ""
  totalTrack: number = 0
  appleDigitalMaster: string = ""
  urgent: boolean = false
  smartLink?: string
  state51Id?: string
  driveId?: string //BMV Drive Folder Id
  mesamMsgId?: string // PUBLISHING Drive Folder Id
  editorialPitchingId?: string // EDITORIAL PITCHING Drive Sheet Id
  forceUploadType?: string
}

export class PaymentHistoryDetail {
  id: number | undefined = undefined
  period = ""
  revenue: number | undefined = undefined
  totalPayment: number | undefined = undefined
  paymentDay: number[] = []
}

export class SocialMedia {
  id?: number
  userId?: number
  url?: string
  created?: string
  actionCount?: number
  icon?: string
}

export class Event {
  id?: number
  userId?: number
  url?: string
  created?: string
  actionCount?: number
  startDate?: Date
  endDate?: Date
  title?: string
  address?: string
}

export type ReleaseDetailProps = {
  releases: Release[]
  fetchReleaseTracks: (limit: number, offset: number, releaseId: string, userId: number) => Promise<void>
  selectRelease: (release?: Release, releaseId?: string) => void
  userId: number
  release: Release
  paginatedTracks: PaginatedData<Track>
  isAdmin: boolean
  shareData: CollaboratorData[] // check this if it is true type (Evren added this line)
}

export type SmartLink = {
  id?: number
  release?: Release
  releaseId?: number
  viewCount?: number
  actionCount?: number
  urls?: SmartLinkUrl[]
}

export type SmartLinkUrl = {
  id?: number
  smartLinkId?: number
  url?: string
  created?: string
  icon?: string
}

export type SmartLinkPreSave = {
  id?: number
  spotifyEmail?: string
  validatedEmail?: string
  created?: string
  userId?: string
}

export type SmartLinkAnalytics = {
  viewCount?: number
  actionCount?: number
  preSaveCount?: number
  subscribeCount?: number
  preSaveList?: SmartLinkPreSave[]
}

export type ArtistSmartLinkProps = {
  user: User
  fetchUserData: () => Promise<void>
}

export type ReleaseSmartLinkProps = {
  release: Release
  releases: Release[]
  selectRelease: (release?: Release, releaseId?: string) => void
}

export type LCReleaseFormDataDetail = {
  id?: string
  attributes: { [key: string]: any }
  relationships: { [key: string]: any }
  type: string
}

export type LCReleaseFormData = {
  data: LCReleaseFormDataDetail
}

export type SpotifyEditorialPitching = {
  id?: number
  trackIsrc?: string
  releaseId?: number
  genre: string[]
  mood: string[]
  style: string[]
  instrumentation: string[]
  description: string
}

export type LCReleaseForm = {
  product: LCReleaseFormData
  offer: LCReleaseFormData
  tracks: LCReleaseFormData[]
  track_offers: LCReleaseFormData[]
  import_tasks: LCReleaseFormData[]
  import_sub_tasks: LCReleaseFormData[]
  records: LCReleaseFormData[]
  logs: LCReleaseFormData[]
}

export type ResidualFormProps = {
  sendToBeatport: boolean
}

export type EditReleaseProps = {
  userId: number
  release: Release
}

export type AssetRegistryProps = {
  user: User
  release: Release
  selectRelease: (release?: Release, releaseId?: string) => void
  fetchUserData: () => Promise<void>
}

export type ReleaseRegistryProps = {
  user: User
  userId: number
  release: Release
  selectRelease: (release?: Release, releaseId?: string) => void
}

export type CatalogInfoProps = {
  pageTitle: string
  breadcrumbs: string
  coverPhoto: string
  title: string
  artists: Artist[]
  UPC: string
  status: string
  releaseDate: string
  onClickEdit?: (isEdit: boolean) => void
  genre?: string
  isrc?: string
}

export type UserExpense = {
  created?: string
  adminFee?: number
  distributionFee?: number
  user?: number
  taxType?: number
  preferredCurrency?: number
  paymentPeriod?: number
}

export type UserCredit = {
  id?: number
  created?: string
  subscriptionType?: string
  userId?: number
  enabled?: boolean
  urgentNewRelease?: number
  takedown?: number
}

export type TrackDetailProps = {
  track: Track
  release: Release
  selectTrack: (track?: Track, trackId?: string) => void
  selectRelease: (release?: Release, releaseId?: string) => Promise<void>
  trackShareData: CollaboratorData[]
  fetchTrackShareData: (trackId: number) => Promise<any>
  setSelectedTrack: (selectedTrack: number | undefined) => void
  selectedTrack: number | undefined
}

export type TrackInformationProps = {
  track: Track
}

export type ProfileProps = {
  saveUserData: (values: User) => void
  saveUserProfilePhoto: (photo: FormData) => Promise<void>
  user: User
}

export type PaymentFormProps = {
  initialValues?: PaymentMethod
  canDelete?: boolean
  editable?: boolean
  edit?: boolean
  setEdit?: React.Dispatch<React.SetStateAction<boolean>>
  adminPanelUserId?: number
  deletePaymentMethod: (id: number) => void
  updatePaymentMethod: (formValues: PaymentMethod) => void
  createPaymentMethod: (f: PaymentMethod, userId?: number) => void
  setAdding?: (adding: boolean) => void
  title?: string
  addPaymentMethod?: boolean
  setAddPaymentMethod?: React.Dispatch<React.SetStateAction<boolean>>
  choosenMethod?: PaymentMethod
}

export class PaymentMethod implements FormValues {
  id?: number
  user?: number
  bankName: string
  accountHolderName: string
  iban: string
  swiftCode?: string
  created?: string
}

export type PaymentPageProps = {
  adminPanelUserId?: number
  fetchPaymentMethods: (userId?: number) => void
  paymentMethods: Record<string, PaymentMethod>
}

export type CatalogFilter = {
  choosenStatus: string[]
  choosenGenre: string[]
  ownerStatus: string
}

export type CatalogProps = {
  paginatedReleases: PaginatedData<Release>
  loggedInUser?: User
  user: User
  fetchCatalogData: (
    limit: number,
    offset: number,
    userId: number,
    filter: CatalogFilter,
    search?: string,
    searchUpc?: string
  ) => Promise<any>
  selectCatalogFilter: (filter: string) => Promise<any>
  selectedCatalogFilter: CatalogFilter
  newReleasePermission: boolean
  updateReleaseLabelCamp: (id: number, labelCampStatus: boolean) => Promise<boolean>
  updateReleaseStatus: (id: number, status: string) => Promise<boolean>
  deleteRelease: (id: number) => Promise<boolean>
}

export class Artist {
  id: number | undefined = undefined
  name: string = ""
}

export class Label {
  id: number | undefined = undefined
  name = ""
}

export class Publisher {
  id: number | undefined = undefined
  name = ""
}

export class CatalogData {
  paginatedReleases: PaginatedData<Release> = new PaginatedData<Release>()
  paginatedReleaseTracks: PaginatedData<Track> = new PaginatedData<Track>()
  selectedRelease: Release = new Release()
  selectedTrack: Track = new Track()
  trackShareData: CollaboratorData[] = []
  shareData: CollaboratorData[] = []
  selectedCatalogFilter: CatalogFilter = {
    choosenStatus: ["active", "draft", "reviewing"],
    ownerStatus: RELEASE_FILTER.ALL,
    choosenGenre: []
  }
}
export class TracksData {}

export type AdminUserProps = {
  paginatedUsers: PaginatedData<User>
  selectedUser: User
  fetchAllUsers: (
    limit: number,
    offset: number,
    filters: { [key: string]: string | number | boolean },
    sortField?: string,
    sortDirection?: "asc" | "desc"
  ) => Promise<void>
  selectUser: (user: User) => void
  fetchLastUpdate: () => Promise<any>
  lastUpdate: string | null
  refreshLabelCamp: () => Promise<any>
  refreshReports: (date: string) => Promise<any>
  refreshSummaries: (date: string, userId: number | undefined) => Promise<any>
}

export type AdminAssetProps = {
  paginatedUserTrackShareData: PaginatedData<UserTrackShareData>
  paginatedUserYoutubeVideoShareData: PaginatedData<UserYoutubeVideoShareData>
  selectedUser: User
  fetchUserTrackShareData: (
    id: number,
    limit: number,
    offset: number,
    filters: { [key: string]: string | number | boolean },
    sortField?: string,
    sortDirection?: "asc" | "desc"
  ) => Promise<void>
  fetchUserYoutubeVideoShareData: (
    id: number,
    limit: number,
    offset: number,
    filters: { [key: string]: string | number | boolean },
    sortField?: string,
    sortDirection?: "asc" | "desc"
  ) => Promise<void>
  selectTrackShareData: (data: UserTrackShareData) => void
  selectYoutubeVideoShareData: (data: UserYoutubeVideoShareData) => void
  deleteTrackShareData: (shareDataId: number) => void
  deleteYoutubeVideoShareData: (shareDataId: number) => void
  selectedTrackShareData: UserTrackShareData
  selectedYoutubeVideoShareData: UserYoutubeVideoShareData
}

export class AdminState {
  paginatedUserTrackShareData = new PaginatedData<UserTrackShareData>()
  paginatedUserYoutubeVideoShareData = new PaginatedData<UserYoutubeVideoShareData>()
  paginatedUsers = new PaginatedData<User>()
  selectedUser: User = null
  selectedUserExpense: UserExpense = null
  adminPanelUserId: number | undefined = undefined
  selectedTrackShareData = new UserTrackShareData()
  selectedYoutubeVideoShareData = new UserYoutubeVideoShareData()
  lastUpdate: string | null
  summaries = new AnalysisSummaries()
  selectedFilter: string = ANALYSIS_FILTER.TOP_TRACKS
  trackShareData: CollaboratorData[] = []
  selectedCollaboratorData = new CollaboratorData()
  affiliateFromList: Affiliate[] = []
  affiliateToList: Affiliate[] = []
}

export type Role = {
  id: number | undefined
  name: string
}

export type MasterTemplateProps = {
  footer?: boolean
}

export type PaginationProps = {
  hidden: boolean
  pageCount: number
  currentPage: number
  onClickPage: (pageNumber: number) => void
  size?: "sm" | "md" | "lg"
}

export type AdminUserFormProps = {
  user: User
  saveUser: (formValues: UserForm) => void
  selectUser: (user: User) => void
  paymentMethodsAdminEntry: (adminPanelUserId?: number) => void
  selectedUserExpense: UserExpense
  fetchUserExpenseData: (userId: number) => Promise<void>
  saveUserExpenseData: (formValues: UserExpenseForm) => Promise<void>
  loggedInUser: User
  refreshSummaryForUser: boolean
  setRefreshSummaryForUser: (refreshSummaryForUser: boolean) => void
  affiliateFromList: Affiliate[]
  affiliateToList: Affiliate[]
  fetchAffiliateList: (userId: number) => Promise<void>
  fetchUserData: () => Promise<void>
}

export class UserForm {
  id: number | undefined = undefined
  username = ""
  password = ""
  firstName = ""
  lastName = ""
  roleId: number
  permissionIds: number[]
  lcUsername = ""
  lcPassword = ""
  sendEmail = false
  bmwCustomId = ""
  validateEmail = true
}

export class UserExpenseForm {
  id?: number | undefined = undefined
  adminFee: number = 5
  distributionFee: number = 30
  user: number | undefined = undefined
  taxType: number = 1
  preferredCurrency: number = 1
  paymentPeriod: number = 1
  publishingKahunaShare: number = 0
}

export type HeaderProps = {
  loggedIn: boolean
  userRole: number
  newReleasePermission: boolean
  getAdavencePermission: boolean
  appliedAffiliateUser: User | undefined
}

export class ShareRole {
  id: number | undefined = undefined
  name = ""
}

export class UserTrackShareRole {
  id: number | undefined = undefined
  roleDetail = new ShareRole()
  role: number | undefined = undefined
  trackShareHolder: number | undefined = undefined
}

export class UserTrackShareData {
  id: number
  userDetail = new User()
  trackDetail = new Track()
  roleList: UserTrackShareRole[] = []
  title = ""
  share: ""
  track: number | undefined = undefined
  user: number | undefined = undefined
}

export class UserYoutubeVideoShareData {
  id: number
  userDetail = new User()
  roleList: UserTrackShareRole[] = []
  title = ""
  share: ""
  youtubeVideo: number | undefined = undefined
  user: number | undefined = undefined
  youtubeVideoDetail = new YoutubeVideo()
}

export type AdminTrackFormProps = {
  user: User
  shareData: UserTrackShareData
  saveTrackShareForm: (formValues: UserTrackShareForm) => void
  selectTrackShareData: (data: UserTrackShareData) => void
  hidden: boolean
  creatingRef: MutableRefObject<boolean>
}

export type AdminYoutubeVideoFormProps = {
  user: User
  shareData: UserYoutubeVideoShareData
  saveYoutubeVideoShareForm: (formValues: UserYoutubeVideoShareForm) => void
  selectYoutubeVideoShareData: (data: UserYoutubeVideoShareData) => void
  hidden: boolean
  creatingRef: MutableRefObject<boolean>
}

export class UserTrackShareForm {
  id: number | undefined = undefined
  user: number | undefined = undefined
  track: number | undefined = undefined
  share = ""
  title = ""
  mainOwner = false
  roles: number[] = []
}

export class UserYoutubeVideoShareForm {
  id: number | undefined = undefined
  user: number | undefined = undefined
  youtubeVideo: number | undefined = undefined
  share = ""
  title = ""
  roles: number[] = []
  trackIsrc = ""
  mainOwner = false
}

export type SearchSelectProps = {
  id?: string
  name?: string
  label?: string
  className?: string
  value?: any
  onChange?: ChangeEventHandler<HTMLInputElement>
  onTextChange?: ChangeEventHandler<HTMLInputElement>
  onKeyPress?: KeyboardEventHandler<HTMLInputElement>
  disabled?: boolean
  options?: SelectOption[]
  placeholder?: string
  loading?: boolean
  multiple?: boolean
  onSelect?: ChangeEventHandler<HTMLInputElement>
  disabledOptions?: any[]
  inputTextClass?: string
  containerStyle?: CSSProperties
}

export type SelectProps = {
  id?: string
  name?: string
  label?: string
  className?: string
  style?: any
  value?: any
  onChange?: ChangeEventHandler<HTMLInputElement>
  disabled?: boolean
  options?: SelectOption[]
  placeholder?: string
  loading?: boolean
  onSelect?: (value: any) => void
  inputTextClass?: string
  buttonClass?: string
}

export type SelectOption = {
  value: string | number
  label: ReactNode
  label2?: string
  type?: string
  cover?: string
}

export type HttpMethods = "get" | "post" | "put" | "delete" | "patch"

export class AnalysisTotal {
  date = ""
  totalNet = 0
}

export class AnalysisTopSongs {
  changeQuantity = 0
  identifier = ""
  percentage = 0
  platform = ""
  quantity = 0
  track = ""
  videoId = ""
  releaseCover = ""
  artistList = []
}

export class AnalysisState {
  totals: AnalysisTotal[] = []
  selectedDate: string = ""
  selectedFilter = ANALYSIS_FILTER.TOP_TRACKS
  summaries = new AnalysisSummaries()
  topSongs: AnalysisTopSongs[] = []
  filterOptions: { [key: string]: KSelectOption[] }
  allSummaries: AnalysisSummary[]
}

export type AnalysisSummaryListProps = {
  currency: string
  selectedFilter: string
  summaries: AnalysisSummaries
}

export class AnalysisSummary {
  [key: string]: any
  id: number | undefined = undefined
  label = ""
  currency = "TRY"
  quantity = 0
  totalAmount = 0
  totalNet: string | number = undefined
  totalNetAll: string | number = undefined
  user = ""
  date = ""
  totalGross: 0
  userName = ""
}

export class ReportsApprovePaymentRequest {
  userId: number
  date: string[]
  paymentDay: string
}

export class AnalysisSummaries {
  platform: AnalysisSummary[] = []
  storeFront: AnalysisSummary[] = []
  topTracks: AnalysisSummary[] = []
}

export type AnalysisTotalsProps = {
  totals: AnalysisTotal[]
  fetchTotals: () => Promise<void>
  fetchFilterOptions: () => Promise<void>
  fetchAllSummaries: (type: string, value: string | number, affiliate: boolean) => Promise<void>
  filterOptions: { [key: string]: KSelectOption[] }
  allSummaries: AnalysisSummary[]
  selectDate: (date: string) => void
  selectedDate: string
  currency: string
}

export type AnalysisChartProps = {
  totals: AnalysisTotal[]
  currency: string
  selectedDate: string
  selectFilter: (filter: string) => void
  //summaries: AnalysisSummaries
  selectedFilter: string
  fetchSummaries: (date: string) => void
  fetchFilterOptions?: () => Promise<void>
  fetchAllSummaries: (
    type: string,
    value: string | number,
    affiliate: boolean,
    startDate?: string,
    endDate?: string
  ) => Promise<void>
  //allSummaries: AnalysisSummary[]
  initialData: AnalysisSummary[]
  filterOptions?: { [key: string]: KSelectOption[] }
  user?: any
}

export type AffiliateChartProps = {
  totals: AnalysisTotal[]
  currency: string
  selectedDate: string
  selectFilter: (filter: string) => void
  //summaries: AnalysisSummaries
  selectedFilter: string
  fetchSummaries: (date: string) => void
  fetchFilterOptions?: () => Promise<void>
  fetchAllSummaries: (
    type: string,
    value: string | number,
    affiliate: boolean,
    startDate?: string,
    endDate?: string
  ) => Promise<void>
  allSummaries: AnalysisSummary[]
  //initialData: AnalysisSummary[]
  filterOptions?: { [key: string]: KSelectOption[] }
  user?: any
}

export type TooltipProps = {
  place?: "top" | "bottom" | "left" | "right"
  content?: ReactNode
  type?: "info" | "warn" | "error"
  width?: number
  disableBackground?: boolean
}

export type ReportsProps = {
  isAdmin: boolean
  paymentHistory: PaymentHistoryDetail[]
  user: User
  fetchReportsData: (userId?: number, approveDisabled?: boolean) => Promise<any>
}

export type AccountProps = {
  user: User
  selected: string
  fetchUserData: () => Promise<void>
}

export class ReportsData {
  paymentHistory: PaymentHistoryDetail[] = []
}

export class UsersState {
  socialMediaList: SocialMedia[] = []
  eventList: Event[]
}

export type RequestConfig = {
  path: string
  method: Method
  data?: any
}

export class CollaboratorData {
  first_name: string
  last_name: string
  email: string
  photo: string | null
  roles: string[]
  role_ids: number[]
  share: ""
  status: number
}

export type TrackShareHolderTableProps = {
  user: User
  trackShareData: CollaboratorData[]
  fetchTrackShareData: (trackId: number) => Promise<any>
  trackId: number
}

export class Currency {
  name = ""
}

export class CurrencyExchange {
  id: number
  exchangeRates: number | undefined = undefined
  currencyTypeDetail = new Currency()
  date: string
  currencyId: number
  created: string
}

export type AdminTrackShareHolderTableProps = {
  user: User
  trackShareData: CollaboratorData[]
  fetchTrackCollaboratorData: (trackId: number) => Promise<any>
  trackId: number
  selectCollaboratorData: (data: CollaboratorData) => void
}

export type AdminCollaboratorFormProps = {
  selectedCollaborator: CollaboratorData
  saveCollaboratorForm: (formValues: AdminCollaboratorShareForm) => void
  selectCollaboratorData: (data: CollaboratorData) => void
  hidden?: boolean
  track_id: number
}

export class AdminCollaboratorShareForm {
  email: string | undefined = undefined
  share: ""
  roles: number[] = []
  track_id: number
  name: string
}

export class DownloadReportButtonProps {
  reportId: string
  user: User
  downloadReport: (quarter: string, userId?: number, approveDisabled?: boolean) => Promise<any>
  isAdmin?: boolean
  selectedUserId?: number
  approveDisabled?: boolean
}

export type InvitationStatus = {
  message: string
  registered: boolean
  status: INVITATION_STATUS
}

export type InvitationAcceptProps = {
  acceptInvitation: (token: string) => Promise<void>
  invitationStatus: InvitationStatus
}

export type InvitationLayoutProps = {
  title?: string
  text?: string
  showLoginButton?: boolean
  children?: ReactNode
}

export type AnalysisTopSongsProps = {
  date: string
  fetchTotals: () => Promise<void>
  fetchTopSongs: (date: string) => Promise<void>
  topSongs: AnalysisTopSongs[]
  dateOptions: { date: string; totalNet: number }[]
  analysis: any
}

export type Scheduler = {
  id: number
  name: string
  description: string
  next_run: string
  previous_run: string
  enabled: boolean
  cron: string
  type: string
  log: boolean
  value: string
  report_list: SchedulerReport[]
}

export type Registration = {
  calendly_id: string
  start_time: string
  calendly_status: string
  db_status: string
  description: string
  created_at: string
  name: string
  email: string
  phone_number: string
  country: string
  role: string
  artists: string
  hearing_source: string
}

export type AssetRegistryState51BatchId = {
  id: number
  batchId: string
  releaseId: number
}

export type AssetRegistryUpcCodes = {
  id: number
  upc: string
  releaseId: number
}

export type AssetRegistryIsrcCodes = {
  id: number
  isrc: string
  releaseId: number
}

export type TerritoryDsp = {
  id: number
  label: string
  image: string
}

export type AssetRegistryTrackShareHolder = {
  id?: number
  name: string
  email: string
  share: string
  isrc: string
  roles: string
}

export type AssetRegistryPublishingShareHolder = {
  id?: number
  name: string
  share: string
  isrc: string
  role: number
}

export type TrackRegistryConsentFile = {
  id?: number
  title: string
  track_isrc: string
  release_id: number
  file_url: string
  asset_id: string
}

export type SchedulerReport = {
  id: number
  scheduler_id: number
  date: string
  status: string
  value: string
  _read: number
  _warning: number
  _error: number
  _insert: number
  _update: number
  _delete: number
  user_id: number
}

export type Affiliate = {
  id?: number
  fromUser?: User
  toUser?: User
  fromUserId: number
  toUserId: number
  commission: number
}

export type PublicSmartLink = {
  release?: Release
  track?: Track
  smartLink?: SmartLink
  preSave?: boolean
}

export type ArtistSmartLink = {
  id?: number
  userId?: number
  viewCount?: number
  link?: string
  user?: User
  socialMediaList?: SocialMedia[]
  releaseList?: Release[]
  eventList?: Event[]
}

export interface CustomFile {
  files: FileList
}

export type RotorVideoOutput = {
  id: number
  title: string
  duration: number
  video_url: string
  style_id: number
  variant_id: number
  progress: number
  progress_message: string
  aspect_ratio: string
}

export type RotorVideoAttributes = {
  video_id: number
  outputs: RotorVideoOutput[]
}

export type RotorVideo = {
  attributes: RotorVideoAttributes
}

export type RotorStyleVariant = {
  id: number
  duration: number
  aspect_ratio: string
  preview: string
  title: string
}

export type RotorStyleAttributes = {
  id: number
  preview: string
  title: string
  variants: RotorStyleVariant[]
}

export type RotorStyle = {
  attributes: RotorStyleAttributes
}

export type Contract = {
  id: number
  created: string
  type: string
  url: string
  userId: string
  signed: boolean
  blob?: any
}

export interface InformationPageForm {
  contactName: string
  country: string
  email: string
  phoneNumber: string
  code: string
  ipAddress: string
}
