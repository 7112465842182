import React, { FC, useEffect, useState } from "react"
import "../styles/smartlink.css"
import { Release, SmartLink, SmartLinkAnalytics, SmartLinkPreSave } from "../../../../types"
import { KSpan, KTitleSpan } from "kahuna-base-react-components"
import { mapToCamelCase } from "../../../../utility"
import { releases } from "../../../../apis"
import { lang } from "../../../../constants/languages"

type AnalyticPageProps = {
  release: Release
}

const AnalyticPage: FC<AnalyticPageProps> = (props) => {
  const { release } = props

  const [smartLinkAnalytic, setSmartLinkAnalytic] = useState<SmartLinkAnalytics>({})

  useEffect(() => {
    releases(`/release/smart_link_analytics/?release_id=${release.id}`, "get").then((resp) => {
      if (resp && resp.data) {
        setSmartLinkAnalytic(mapToCamelCase(resp.data))
      }
    })
  }, [])

  return (
    <div style={{ minWidth: 616 }}>
      <div>
        <div>
          <KTitleSpan text={lang.catalog.smart_link.analytics_title} fontSize={32} />
        </div>
        <div className="mt-4 flex gap-2" style={{ width: "100%" }}>
          <div className="analytic-count" style={{ width: "25%" }}>
            <div className="flex gap-1">
              <img src="/account_icons/eye.svg" width={20} />
              <KSpan color="#000" fontWeight={500} text={lang.catalog.smart_link.view} />
            </div>
            <div className="mt-8">
              <KTitleSpan text={smartLinkAnalytic.viewCount?.toString() || " - "} fontSize={32} />
            </div>
          </div>
          <div className="analytic-count" style={{ width: "25%" }}>
            <div className="flex gap-1">
              <img src="/account_icons/link.svg" width={20} />
              <KSpan color="#000" fontWeight={500} text={lang.catalog.smart_link.click}  />
            </div>
            <div className="mt-8">
              <KTitleSpan text={smartLinkAnalytic.actionCount?.toString() || " - "} fontSize={32} />
            </div>
          </div>
          <div className="analytic-count" style={{ width: "25%" }}>
            <div className="flex gap-1">
              <img src="/account_icons/music-album.svg" width={20} />
              <KSpan color="#000" fontWeight={500} text={lang.catalog.smart_link.pre_save}  />
            </div>
            <div className="mt-8">
              <KTitleSpan text={smartLinkAnalytic.preSaveCount?.toString() || " - "} fontSize={32} />
            </div>
          </div>
          <div className="analytic-count" style={{ width: "25%" }}>
            <div className="flex gap-1">
              <img src="/account_icons/add-line.svg" width={20} />
              <KSpan color="#000" fontWeight={500} text={lang.catalog.smart_link.subscriber}  />
            </div>
            <div className="mt-8">
              <KTitleSpan text={smartLinkAnalytic.subscribeCount?.toString() || " - "} fontSize={32} />
            </div>
          </div>
        </div>
        <div className="mt-8">
          <KTitleSpan text={lang.catalog.smart_link.analytics_subtitle} fontSize={20} />
        </div>
        <div className="mt-6">
          {smartLinkAnalytic.preSaveList?.map((preSave: SmartLinkPreSave) => {
            return (
              <div key={preSave.id} className="mt-4 flex" style={{ width: "100%" }}>
                <div style={{ width: "10%" }}>
                  <img
                    className="cursor-pointer"
                    src="/catalog_icons/user-fill.svg"
                    style={{ width: 48, height: 48, border: "1px solid grey", borderRadius: 999, padding: 10 }}
                    onClick={() => window.open(`https://open.spotify.com/user/${preSave.userId}`, "_blank")}
                  />
                </div>
                <div className="flex items-center justify-center" style={{ width: "30%" }}>
                  <KSpan color="#111" text={preSave.spotifyEmail || "-"} hoverText="Spotify Email" />
                </div>
                <div className="flex items-center justify-center" style={{ width: "30%" }}>
                  <KSpan color="#111" text={preSave.validatedEmail || "-"} hoverText="Subscribe Email" />
                </div>
                <div className="flex items-center justify-end" style={{ width: "30%" }}>
                  <KSpan color="#111" text={preSave.created?.substring(0, 10) || "-"} hoverText="Created" />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default AnalyticPage
