import * as React from "react"
import { FC } from "react"
import { Disclosure } from "@headlessui/react"
import { HEADER_PATHS, PERMISSIONS, USER_ROLE, errorToastOptions, loadingToastOptions } from "../../../constants"
import ProfileDropdownMenu from "../../layouts/navigation/ProfileDropdownMenu"
import Notifications from "./Notifications"
import { connect } from "react-redux"
import { RootState } from "../../../store"
import { HeaderProps } from "../../../types"
import { useLocation, useNavigate } from "react-router-dom"
import { KButton, KLogo, KSpan } from "kahuna-base-react-components"
import ProfileListElement from "../../layouts/navigation/ProfileListElement"
import { users } from "../../../apis"
import { toast } from "react-toastify"
import { encodeData } from "../../../utility"
import { lang } from "../../../constants/languages"

export const Header: FC<HeaderProps> = (props) => {
  const location = useLocation()
  const path = location.pathname
  const segments = path.split("/").filter(Boolean)

  const navigate = useNavigate()

  const visible =
    window.location.pathname === "/" || HEADER_PATHS.some((path) => window.location.pathname.startsWith(path))

  const handleNewReleaseClick = () => {
    const newReleaseUrl = "https://distribution.kahuna.io/"
    window.open(newReleaseUrl, "_blank")
  }

  const handleGetAdvanceClick = async () => {
    users(`/get_advance`, "get")
      .then((resp) => {
        if (!resp || !resp.data) {
          toast.error(lang.header.error, errorToastOptions)
          return
        }
        if (!resp.data.spotify) {
          toast.success(lang.header.success, loadingToastOptions)
          navigate("/account/social-media-links")
        } else {
          const url = resp.data.url
          const token = resp.data.token

          const encodedString = encodeData(token, 1) // 1 minute expiration
          window.open(`${url}?royalty=${encodedString}`, "_blank")?.focus()
        }
      })
      .catch(() => {
        toast.error(lang.header.error, errorToastOptions)
      })
  }

  if (!visible) return null

  const renderGoBack = (text: string) => {
    return (
      <div
        style={{ cursor: "pointer" }}
        onClick={() => navigate(-1)}
        className={`w-auto flex flex-row gap-[8px] justify-start items-center py-[8px] px-[10px] rounded-[10px] group-hover:!bg-[#f0f0f0]`}
      >
        <span className="w-[20px] aspect-square flex justify-center items-center p-0">
          <img className="w-full h-full" src={"/navigation_icons/arrow-left.svg"}></img>
        </span>
        <span className="flex items-center">
          <KSpan text={text} color="#111" fontWeight={500} fontSize={12} />
        </span>
      </div>
    )
  }

  return (
    <Disclosure as="nav" className="bg-white">
      {({ open }) => (
        <>
          <div className="ml-8 mr-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="flex-1 flex items-center sm:items-stretch sm:justify-start">
                <KLogo borderRadius={1} width={48} height={48} logoColor="white" primaryTextVisible={true} />
                {path.includes("account") && (
                  <div className="ml-8">
                    <ProfileListElement
                      text={props.userRole === USER_ROLE.Affiliate ? "Back to Affiliate" : lang.header.back_to_overview}
                      href={props.userRole === USER_ROLE.Affiliate ? "/affiliate" : "/overview"}
                      iconPath="/navigation_icons/arrow-left.svg"
                    />
                  </div>
                )}
                {path.includes("catalog") && segments.length === 2 && (
                  <div className="ml-4">
                    <ProfileListElement
                      text={lang.header.back_to_releases}
                      href="/catalog"
                      iconPath="/navigation_icons/arrow-left.svg"
                    />
                  </div>
                )}
                {path.includes("catalog") && segments.length === 3 && (
                  <div className="ml-4">{renderGoBack("Back to Previous Page")}</div>
                )}
                {path.includes("analytics") && (
                  <div className="ml-4">
                    <ProfileListElement
                      text={lang.header.back_to_overview}
                      href="/catalog"
                      iconPath="/navigation_icons/arrow-left.svg"
                    />
                  </div>
                )}
              </div>
              <div className="inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 gap-3">
                <Notifications />
                {props.getAdavencePermission && (
                  <>
                    <div className="ml-1">
                      <KButton
                        text={lang.header.get_advance}
                        onClick={() => handleGetAdvanceClick()}
                        leftIcon="/account_icons/money-dollar-circle-line.svg"
                        background="#fff"
                        height="36px"
                      ></KButton>
                    </div>
                    <img src="/vector.svg"></img>
                  </>
                )}
                <ProfileDropdownMenu />
              </div>
            </div>
          </div>
        </>
      )}
    </Disclosure>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    loggedIn: state.auth.loggedIn,
    userRole: state.auth.user.roleId,
    appliedAffiliateUser: state.auth.user.appliedAffiliateUser,
    newReleasePermission: state.auth.user.permissionUser?.some((item) => {
      return item.permission && item.permission.id === PERMISSIONS.NewRelease
    }),
    getAdavencePermission: state.auth.user.permissionUser?.some((item) => {
      return item.permission && item.permission.id === PERMISSIONS.GetAdvance
    })
  }
}

export default connect(mapStateToProps, null)(Header)
