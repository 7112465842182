import { KSpan } from "kahuna-base-react-components"
import React from "react"
import { lang } from "../../../../constants/languages"

const AnalysisListComponent = ({ i, row, platform }) => {
  // Bu yapi nasil generic yapilacak. Generic yapmak icin key'leri kullanarak bir map benzeri yapi
  // olusturmak gerekebilir. Ayrıca o kismi halletsem bile CalendarDate kismini map'e nasıl dahil edecegim. Farkli
  // bir component oldugu icin.( Conditional rendering yapılabilir ama kodda karisikliga neden olur mu?)
  // columnTitles'daki width degerleri columns arrayinden aliniyor.Ancak burada map kullanilmadigi icin manuel olarak girilmis.

  const divHeight = 40 // bu div height ile lineHeight degeri birlikte kullanılacak.(Metinin ortalanmasi icin)

  const formatQuantity = (quantity: number): string => {
    if (Math.abs(quantity) < 1000) {
      return `${quantity}`
    } else if (Math.abs(quantity) >= 1000 && Math.abs(quantity) < 1000000) {
      return `${(quantity / 1000).toFixed(1)}K`
    } else if (Math.abs(quantity) >= 1000000 && Math.abs(quantity) < 1000000000) {
      return `${(quantity / 1000000).toFixed(1)}M`
    } else {
      return `${(quantity / 1000000000).toFixed(1)}B`
    }
  }

  const renderReleaseCover = () => {
    const cover = platform === "youtube" ? "/account_icons/social_media_icons/youtube.svg" : row.releaseCover

    return (
      <img
        width={48}
        height={48}
        src={cover}
        style={{
          borderRadius: 6,
          boxShadow: "0px 16px 32px -12px rgba(88, 92, 95, 0.10)"
        }}
      />
    )
  }

  return (
    <li key={`data-row-${i}`}>
      <div className={`rounded-lg p-[20px] gap-[20px] h-[80px] table-row-hover`}>
        <div className={`h-[${divHeight}px] flex flex-row justify-between items-center `}>
          <div className="w-[60%] flex flex-row justify-start">
            <div className="flex">
              <div style={{ marginRight: 16 }}>{renderReleaseCover()}</div>
              <div className="grid items-center">
                <KSpan text={row.track} color="#111" fontWeight={500} fontSize={16} lineHeight="24px" />
                {row.artistList?.length > 0 && (
                  <KSpan
                    text={row.artistList.map((artist) => artist.name).join(", ")}
                    fontWeight={400}
                    lineHeight="24px"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="w-[20%] flex flex-row justify-center">
            <KSpan
              text={`${formatQuantity(row.quantity)}`}
              color="#111"
              fontWeight={500}
              letterSpacing="-0.176px"
              fontSize={16}
              hoverText={lang.overview.analysis_top_songs.list_component_streams_hover}
            />
          </div>
          <div className="w-[20%] flex flex-row justify-center">
            <KSpan
              text={`${row.changeQuantity > 0 ? "+" : ""}${formatQuantity(row.changeQuantity)}`}
              color={row.changeQuantity > 0 ? "#45D865" : "#E52B2B"}
              fontWeight={500}
              letterSpacing="-0.176px"
              fontSize={15}
              hoverText={lang.overview.analysis_top_songs.list_component_change_hover}
            />
          </div>
        </div>
      </div>
    </li>
  )
}

export default AnalysisListComponent
