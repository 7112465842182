import { KButton, KSelectRange, KSpan } from "kahuna-base-react-components"
import React, { useEffect, useRef, useState } from "react"
import { RootState } from "../../../store"
import { connect } from "react-redux"
import { fetchAllSummaries, fetchFilterOptions } from "../../../actions/analysisActions"
import { KSelectOption } from "kahuna-base-react-components/dist/components/KDropdown/KDropdown"
import { AnalysisSummary } from "../../../types"
import { analysisFilterOptionsKDropdown, countryIcons } from "../../../constants"
import { MultiValue } from "react-select"
import {
  addCommasToNumber,
  formatDateObject,
  getCurrency,
  getSummaries,
  mapListToCamelCase,
  subOptionsSetter
} from "../../../utility"
import { ChartOptions } from "chart.js"
import { Bar } from "react-chartjs-2"
import { DateRangeType } from "kahuna-base-react-components/dist/components/KSelectRange/KSelectRange"
import { lang } from "../../../constants/languages"

interface ChartData {
  label: string
  countryCode: string
  totalNet: number
}

const CountryChart = ({
  fetchFilterOptions,
  filterOptions,
  initialData,
  currency
}: {
  fetchFilterOptions: () => Promise<void>
  filterOptions?: { [key: string]: KSelectOption[] }
  initialData: AnalysisSummary[]
  currency: string
}) => {
  const [selectedSubOptions, setSelectedSubOptions] = useState<KSelectOption | MultiValue<KSelectOption> | undefined>(
    [] as KSelectOption[]
  )
  const [subOptions, setSubOptions] = useState<KSelectOption[]>([])
  const [countries, setCountries] = useState<KSelectOption[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState<AnalysisSummary[]>([] as AnalysisSummary[])
  const [chartData, setChartData] = useState<ChartData[]>([] as ChartData[])

  const [dateValues, setDateValues] = useState<DateRangeType>([
    new Date(new Date().getFullYear(), 0),
    new Date(new Date().getFullYear(), 11)
  ])

  const hoveredIndexRef = useRef<number>(-1)
  const [viewMore, setViewMore] = useState<boolean>(false)


  useEffect(() => {
    const subOptionSetting = async () => {
      if (filterOptions) {
        setLoading(true)
        await subOptionsSetter(analysisFilterOptionsKDropdown[0], filterOptions, setSubOptions)
        setLoading(false)
      }
    }
    const countriesSetting = async () => {
      if (filterOptions) {
        setLoading(true)
        await subOptionsSetter(analysisFilterOptionsKDropdown[2], filterOptions, setCountries)
        setLoading(false)
      }
    }
    if (filterOptions) {
      countriesSetting()
    }
  }, [filterOptions])


  useEffect(() => {
    const getAllSummary = async () => {
      if (dateValues && dateValues[0] && dateValues[1]) {
        const startDate = formatDateObject(dateValues[0])
        const endDate = formatDateObject(dateValues[1])
        const responseData = await getSummaries("storeFront", "allCountries", false, startDate, endDate)
        setData(mapListToCamelCase(responseData))
      }
    }

    getAllSummary().then(() => {})
  }, [dateValues])

  useEffect(() => {
    if (initialData) {
      setData(initialData)
    }
  }, [initialData])

  useEffect(() => {
    if (!data) return
    if (data && !loading && countries.length > 0) {
      const chartDataArray: { label: string; countryCode: string; totalNet: number}[] = []
      countries.forEach((option) => {
        const totalNetResult = data
          .filter((summary) => summary.label === option.value2)
          .reduce((sum, currentValue) => {
            sum += Number(currentValue.totalNet)
            return sum
          }, 0)
        chartDataArray.push({
          label: option.label,
          countryCode: option.value2 || String(option.value),
          totalNet: totalNetResult
        })
      })
      const sortedChartDataArray = chartDataArray.sort((a, b) => b.totalNet - a.totalNet)
      setChartData(sortedChartDataArray)
    }
  }, [data])

  const myChartData = {
    labels: viewMore
      ? chartData.map((data) => (data.label === "Other countries total" ? "Other countries" : data.label))
      : chartData.slice(0, 6).map((data) => (data.label === "Other countries total" ? "Other countries" : data.label)),
    datasets: [
      {
        label: lang.analytics.revenue,
        data: viewMore
          ? chartData.map((data) => Number(data.totalNet.toFixed(2)))
          : chartData.slice(0, 6).map((data) => Number(data.totalNet.toFixed(2))),
        backgroundColor: "#7C2DFC",
        hoverBackgroundColor: "#7C2DFC", // Disable hover color
        hoverBorderWidth: 0, // Disable hover border width
        yAxisID: "y",
        borderSkipped: false,
        barThickness: 16
      }
    ]
  }

  const chartRef = useRef<any>(null)
  const imagesRef = useRef<HTMLImageElement[]>([]) // Store the loaded image in a ref


  useEffect(() => {
    // Preload the images once when the component mounts
    const loadImages = () => {
      const promises = chartData.map((data, index) => {
        return new Promise((resolve) => {
          const img = new Image()
          img.src = countryIcons[data?.label]
          img.onload = () => {
            imagesRef.current[index] = img // Store each image in the ref array
            resolve(img)
          }
        })
      })

      Promise.all(promises).then(() => {
        if (chartRef.current) {
          chartRef.current.update() // Trigger chart update after all images have loaded
        }
      })
    }
    if (chartData.length > 0) {
      loadImages()
    }
  }, [chartData])

  const imagePlugin = {
    id: "imagePlugin",
    afterDatasetsDraw: (chart) => {
      const ctx = chart.ctx
      ctx.imageSmoothingEnabled = true
      ctx.imageSmoothingQuality = 'high'
      const yAxis = chart.scales.y

      yAxis.ticks.forEach((tick, index) => {
        const y = yAxis.getPixelForTick(index)
        const x = yAxis.left - 28

        const image = imagesRef.current[index]
        if (image) {
          if (tick.label === "Spotify") {
            ctx.drawImage(image, x, y - 12, 80, 24.0135478)
          } else {
            ctx.drawImage(image, x, y - 11, 22, 22)
          }
        } else {
        }
      })
    }
  }

  const circlePlugin = {
    id: "circlePlugin",
    afterDatasetsDraw: (chart) => {
      const ctx = chart.ctx
      const meta = chart.getDatasetMeta(0) // Assuming we are working with the first dataset

      // Define circle properties
      const innerRadius = 4 // Inner radius of the ring
      const outerRadius = 6 // Outer radius of the ring
      const ringColor = "#FFF" // Color of the ring

      meta.data.forEach((bar, index) => {
        const x = bar.x // X position of the bar
        const y = bar.y // Y position of the bar
        const width = bar.width
        // Draw a circle only on the hovered bar
        if (hoveredIndexRef.current === index && width > 16) {
          ctx.beginPath()
          ctx.fillStyle = ringColor
          ctx.arc(x - 8, y, outerRadius, 0, Math.PI * 2, false) // outer (filled)
          ctx.arc(x - 8, y, innerRadius, 0, Math.PI * 2, true) // outer (filled)
          ctx.fill()
        }
      })
    }
  }

  const options: ChartOptions<"bar"> = {
    onHover: (event, elements, chart) => {
      if (elements.length > 0) {
        const index = elements[0].index

        hoveredIndexRef.current = index

        if (chartRef.current) {
          chartRef.current.update() // Update the chart to apply changes
        }
      } else {
        hoveredIndexRef.current = -1

        if (chartRef.current) {
          chartRef.current.update() // Update the chart to apply changes
        }
      }
    },
    responsive: true,
    animation: {
      duration: 1000 // default is 1000
    },
    maintainAspectRatio: false,
    indexAxis: "y",
    layout: {
      padding: {
        left: 40
      }
    },
    plugins: {
      legend: {
        display: false,
        labels: {
          color: "#000",
          font: {
            family: "Inter",
            weight: 500,
            size: 13,
            lineHeight: "13px"
          }
        }
      },
      datalabels: {
        display: false
      },
      tooltip: {
        displayColors: false,
        caretSize: 0,
        caretPadding: 10,
        xAlign: "center",
        yAlign: "bottom",
        backgroundColor: "#000",
        bodyFont: {
          family: "Inter",
          weight: 400,
          size: 14,
          lineHeight: "20px"
        },
        callbacks: {
          label: (context) => {
            //let label = context.dataset.label || ""
            const sign = getCurrency(currency)

            if (context.parsed.y !== null) {
              //label += ` ${sign}${addCommasToNumber(context.parsed.y.toFixed(2))}` It can be like this but below one is more reliable
              const label = ` ${sign}${addCommasToNumber(Number(chartData[context.dataIndex].totalNet).toFixed(2))}`
              return label
            } else {
              return ""
            }
          },

          title: (context) => ""
        }
      }
    },
    scales: {
      x: {
        grid: {
          display: true,
          color: "rgba(183, 183, 183, 0.30)",
          drawTicks: false
        },
        border: {
          display: false,
          dash: [3, 3]
        },
        ticks: {
          display: true,
          color: "#999",
          font: {
            family: "Inter",
            weight: 500,
            size: 11,
            lineHeight: "12px"
          }
        }
      },
      y: {
        grid: {
          display: false
        },
        border: {
          display: false,
          color: "#999"
        },
        ticks: {
          display: true,
          color: "#000",
          font: {
            family: "Inter",
            size: 14,
            weight: 500,
            lineHeight: "20px"
          },
          crossAlign: "far",
          padding: 15
        }
      }
    },
    elements: {
      bar: {
        borderRadius: {
          bottomLeft: 6,
          bottomRight: 6,
          topLeft: 6,
          topRight: 6
        }
      }
    }
  }

  return (
    <div
      className="w-full p-5 flex flex-col justify-start gap-3"
      style={{
        borderRadius: "10px",
        border: "1px solid var(--stroke-soft-200, #F3F3F3)",
        background: " var(--bg-white-0, #FFF)",
        boxShadow: "0px 2px 4px 0px rgba(27, 28, 29, 0.04)"
      }}
    >
      <div className="flex flex-row justify-between">
        <div className="flex flex-row gap-1 items-center pl-2.5">
          <img src="/analytics_icons/earth.svg" alt="earth-icon" />
          <KSpan text={lang.analytics.listened_country} color="#000" fontWeight={500} />
        </div>
        <div className="flex flex-row gap-1">
          <div>
            <KSelectRange
              value={dateValues}
              onChange={(event) => {
                setDateValues(event)
              }}
            />
          </div>
        </div>
      </div>
      <div className="grow flex flex-row items-center w-full ">
        {chartData && chartData.length > 0 && (
          <Bar
            data={myChartData}
            options={options}
            plugins={[imagePlugin, circlePlugin]}
            style={{
              height: "450px",
              width: "100%"
            }}
          />
        )}
      </div>
      <div className="flex items-center justify-center">
        <KButton
          text={viewMore ? lang.analytics.view_less_button : lang.analytics.view_more_button}
          background="#FFF"
          height="32px"
          width="auto"
          onClick={() => setViewMore(!viewMore)}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    filterOptions: state.analysis.filterOptions,
    allSummaries: state.analysis.allSummaries,
    currency: state.auth.user.preferredCurrency
  }
}

export default connect(mapStateToProps, { fetchFilterOptions, fetchAllSummaries })(CountryChart)
