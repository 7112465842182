import React, { FC, useEffect, useState } from "react"
import "../styles/smartlink.css"
import { smartLinks } from "../../../../apis"
import { useNavigate, useParams } from "react-router-dom"
import { Artist, PublicSmartLink, SmartLinkUrl } from "../../../../types"
import { getSocialMediaIcon, mapToCamelCase, mapToSnakeCase, renderSiteTitle } from "../../../../utility"
import { KButton, KLogo, KSpan, KTitleSpan, KTooltip } from "kahuna-base-react-components"
import Loading from "../../../ui/Loading"
import PlaylistSelectModal from "../modal/PlaylistSelectModal"
import { KSelectOption } from "kahuna-base-react-components/dist/components/KDropdown/KDropdown"
import { toast } from "react-toastify"
import { errorToastOptions, successToastOptions } from "../../../../constants"

// @ts-ignore
import KahunaLogo from "../../../../assets/logo-icons/kahuna.png"

//@ts-ignore
import SpotifyLogo from "../../../../assets/release-platform-icons/SpotifyLogo.png"
import { lang } from "../../../../constants/languages"

const ReleaseSmartLinkPublicPage: FC = () => {
  const [publicSmartLink, setPublicSmartLink] = useState<PublicSmartLink>()
  const [playlistOptions, setPlaylistOptions] = useState<KSelectOption[]>([])
  const [loading, setLoading] = useState(false)
  const [playlistModalOpen, setPlaylistModalOpen] = useState(false)
  const [preSaveId, setPreSaveId] = useState<number>()

  const params = useParams()
  const navigate = useNavigate()

  const fetchSmartLinks = async () => {
    const resp = await smartLinks.get(`/release/?smart_link=${params.smartLink}`)
    if (resp && resp.data) {
      const result = mapToCamelCase(resp.data)
      const smartLinkUrls =
        result.smartLink?.urls.map((smartLinkUrl: SmartLinkUrl) => {
          const baseUrl = smartLinkUrl.url?.replace("https://", "").replace("http://", "").split("/")[0]
          return { ...smartLinkUrl, icon: `https://${baseUrl}/favicon.ico` }
        }) || []
      const publicSmartLink = { ...result, smartLink: { ...result.smartLink, urls: smartLinkUrls } }

      setPublicSmartLink(publicSmartLink)
    }
  }

  const postSpotifyPreSave = async () => {
    setLoading(true)
    const smartLink = localStorage.getItem("smart-link")

    const code = new URLSearchParams(window.location.search).get("code")
    const postResponse = await smartLinks.post(`/spotify_login/?code=${code}&smart-link=${smartLink}`)

    if (postResponse && postResponse.data && postResponse.data.success) {
      const playlistResponse = await smartLinks.get(`/spotify_playlists/`)
      if (playlistResponse?.data) {
        setPlaylistOptions(
          playlistResponse.data.map((playlist, _index) => {
            return {
              label: playlist["name"],
              value: _index,
              value2: playlist["id"]
            }
          })
        )
        setPlaylistModalOpen(true)
      }
    }

    setLoading(false)
    navigate(`/smart-link/${localStorage.getItem("smart-link")}`)
  }

  useEffect(() => {
    if (params.smartLink && params.smartLink === "auth") {
      postSpotifyPreSave()
    }
  }, [])

  const onSelectPlaylist = async (selected: KSelectOption) => {
    const preSaveResponse = await smartLinks.post(
      `/pre_save_playlist/?upc=${publicSmartLink?.release?.upc}&playlist_id=${selected.value2}`,
      {
        upc: publicSmartLink?.release?.upc,
        playlist_id: selected.value2,
        release_date: publicSmartLink?.release?.releaseDate,
        smart_link_id: publicSmartLink?.smartLink?.id
      }
    )
    if (preSaveResponse?.data?.success) {
      toast.success(lang.catalog.smart_link.success.registered_album, successToastOptions)
    } else {
      toast.error(lang.catalog.smart_link.error.error, errorToastOptions)
    }

    if (preSaveResponse?.data?.pre_save_id) {
      setPreSaveId(preSaveResponse?.data?.pre_save_id)
    }
  }

  useEffect(() => {
    if (params.smartLink && params.smartLink !== "auth") {
      localStorage.setItem("smart-link", params.smartLink)
      fetchSmartLinks()
    }
  }, [params.smartLink])

  const renderPill = (text: string) => {
    return (
      <div style={{ borderRadius: 4, backgroundColor: "#F5F5F5", padding: 4, paddingTop: 0 }} key={Math.random()}>
        <KSpan text={text} fontSize={10} fontWeight={500} lineHeight="12px" color="#111" letterSpacing="0.22px" />
      </div>
    )
  }

  const onPreSave = async () => {
    await smartLinks.get(`/init_pre_save/?release_id=${publicSmartLink?.release?.id}`)

    const authUrl = await smartLinks.get(`/spotify_redirect/`)
    window.open(authUrl.data, "_self")
  }

  const renderPreSave = () => {
    return (
      <div className="ml-4 mr-4 flex gap-4 p-2 smartlink-row mt-4 mb-4">
        <div className="items-center flex justify-between" style={{ width: "100%" }}>
          <img src={SpotifyLogo} style={{ height: 32 }} />
          <KTooltip
            position="top"
            children={
              <KButton
                width="100px"
                disabled={true}
                text={lang.catalog.smart_link.pre_save}
                height="40px"
                textColor="white"
                shadowDisabled={true}
                background="#1ED760"
                onClick={() => onPreSave()}
              />
            }
            content={
              <div style={{ width: 120 }}>
                <KSpan text={lang.catalog.smart_link.coming_soon} />
              </div>
            }
          />
        </div>
      </div>
    )
  }

  const renderIcon = (url: string, icon: string) => {
    const socialMediaIcon = getSocialMediaIcon(url)
    const platformIcon = socialMediaIcon || icon
    const padding = url.includes("www.youtube.com") ? 2 : 6
    return (
      <img
        src={platformIcon}
        style={{ padding, width: 32, height: 32 }}
        onError={(e: any) => {
          e.target.src = KahunaLogo
          e.target.style.padding = 0
        }}
      />
    )
  }

  const renderSmartLinks = () => {
    return (
      <div>
        {publicSmartLink?.smartLink?.urls?.map((smartLinkUrl: SmartLinkUrl) => {
          return (
            <div className="ml-4 mr-4 flex p-2 smartlink-row mt-2" key={smartLinkUrl.id}>
              {smartLinkUrl.url?.includes("spotify") ? (
                <img src={SpotifyLogo} style={{ borderRadius: 10, height: 28 }} />
              ) : (
                renderIcon(smartLinkUrl.url || "", smartLinkUrl.icon || "")
              )}

              <div className="items-center flex justify-between" style={{ width: "100%" }}>
                <KSpan
                  text={!smartLinkUrl.url?.includes("spotify") ? renderSiteTitle(smartLinkUrl.url) : ""}
                  fontSize={14}
                  color="#1F1F1F"
                  fontWeight={600}
                />
                <KButton
                  width="54px"
                  text={lang.smart_link.play_button}
                  height="28px"
                  background="transparent"
                  onClick={() => window.open(smartLinkUrl.url, "_blank")}
                />
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div
      className="flex items-center justify-center"
      style={{ background: "#F5F5F5", height: "100%", overflowY: "scroll" }}
    >
      {loading && <Loading />}
      <PlaylistSelectModal
        open={playlistModalOpen}
        onClose={() => setPlaylistModalOpen(false)}
        options={playlistOptions}
        onSelectPlaylist={onSelectPlaylist}
        preSaveId={preSaveId}
        albumTitle={publicSmartLink?.release?.title}
        releaseDate={publicSmartLink?.release?.releaseDate}
      />
      {publicSmartLink && (
        <div className="flex flex-col items-center" style={{ height: "100%", paddingTop: 16, paddingBottom: 16 }}>
          <div className="smartlink-container">
            <img
              src={publicSmartLink?.release?.coverFileUrl}
              style={{ width: "300px", borderTopLeftRadius: 10, borderTopRightRadius: 10 }}
            />
            <div className="flex justify-center mt-2 pl-2 pr-2" style={{ maxWidth: 300, textAlign: "center" }}>
              <KTitleSpan text={publicSmartLink?.release?.title || ""} fontSize={32} lineHeight="46px" />
            </div>
            <div className="flex justify-center gap-2 mt-1 mb-4 pl-2 pr-2">
              {renderPill(publicSmartLink?.release?.artistsList?.map((artist: Artist) => artist.name).join(", ") || "")}
              {renderPill(publicSmartLink?.release?.productionYear || " - ")}
              {renderPill(
                `${publicSmartLink?.release?.totalTrack} ${
                  (publicSmartLink?.release?.totalTrack || 0) > 1
                    ? lang.common.song_text_multi
                    : lang.common.song_text_single
                }`
              )}
            </div>
            {publicSmartLink?.preSave ? renderPreSave() : renderSmartLinks()}
            <div className="mb-2" />
          </div>
          <div>
            <div className="flex justify-center items-center gap-3">
              <KSpan text="Powered by" color="#989898" fontSize={14} />
              <KLogo width={32} height={32} borderRadius={5} />
              <KTitleSpan text="Kahuna" fontSize={18} />
            </div>
            <div className="flex justify-center mb-2">
              <KSpan text={lang.smart_link.consent_text} />
            </div>
            <div className="flex justify-center mb-4">
              <a
                href="https://kahuna.io/pages/privacy-policy"
                target="_blank"
                className="underline"
                style={{ marginTop: -3, marginLeft: 4, marginRight: 4 }}
              >
                <KSpan text={lang.common.privacy_policy} color="#1F1F1F" fontSize={14} />
              </a>
              <KSpan text={lang.common.and} />
              <a
                href="https://kahuna.io/pages/terms-of-services"
                target="_blank"
                className="underline"
                style={{ marginTop: -3, marginLeft: 4, marginRight: 4 }}
              >
                <KSpan text={lang.common.terms_of_service} color="#1F1F1F" fontSize={14} />
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ReleaseSmartLinkPublicPage
