import React, { FC, useEffect, useState } from "react"
import { RootState } from "../../../../../store"
import { connect } from "react-redux"
import Loading from "../../../../ui/Loading"
import { useNavigate } from "react-router-dom"
import { KLogo, KSpan, KTitleSpan } from "kahuna-base-react-components"
import { Release, SelectOption, User } from "../../../../../types"
import { users } from "../../../../../apis"
import { mapListToCamelCase } from "../../../../../utility"
import NavigationTab from "./NavigationTab"
import { NewReleaseActions, TakedownActionTabs } from "../../../../../actions/types"
import FinishRelease from "./finish-release/FinishRelease"
import Takedown from "./takedown/Takedown"
import { toast } from "react-toastify"
import { loadingToastOptions } from "../../../../../constants"
import { lang } from "../../../../../constants/languages"
import "./styles/NewReleaseAction.css"
import { selectRelease } from "../../../../../actions/catalogActions"

const headerHeight = 88

type NewReleaseActionProps = {
  user: User
  selectRelease: (release?: Release, releaseId?: string) => void
}

const NewReleaseAction: FC<NewReleaseActionProps> = (props) => {
  const { user, selectRelease } = props
  const [loading, setLoading] = useState(true)
  const [releaseList, setReleaseList] = useState<Release[]>([])
  const [selectedTab, setSelectedTab] = useState(TakedownActionTabs.SPOTIFY_ARTIST)
  const [selectedPage, setSelectedPage] = useState(NewReleaseActions.FINISH_RELEASE)
  const [selectedTakedownArtist, setSelectedTakedownArtist] = useState<SelectOption>()

  const navigate = useNavigate()

  useEffect(() => {
    if (!props.user.productionHolder || !props.user.copyrightHolder) {
      toast.success(lang.catalog.asset_registry.success.production_and_copyright_holder, loadingToastOptions)
      navigate("/account/copyright-and-production")
      return
    }

    users(`/releases/?status=draft`, "get")
      .then((resp) => {
        const releaseList = mapListToCamelCase(resp.data.results)
        setReleaseList([...releaseList])

        const takedownCredit = user.userCredit?.takedown || 0
        if (releaseList.length === 0 && takedownCredit === 0) {
          navigate("/catalog/asset-registry/new")
        }
        setLoading(false)
      })
      .catch(() => setLoading(false))
  }, [])

  return (
    <div
      style={{
        height: "100vh",
        backgroundColor: "#F0F0F0",
        padding: 10,
        scrollbarWidth: "thin",
        scrollbarColor: "#E7E7E7 #F5F5F5"
      }}
    >
      {loading && <Loading />}
      <div
        style={{
          backgroundColor: "#fff",
          height: "100%",
          width: "100%",
          borderRadius: 10,
          boxShadow: "0px 2px 2px 0px lightgray"
        }}
      >
        <div
          className="flex"
          style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
            height: `${headerHeight}px`
          }}
        >
          <div style={{ textAlign: "left", cursor: "pointer", padding: 22 }} onClick={() => navigate(-1)}>
            <KLogo
              width={48}
              height={48}
              primaryTextVisible={false}
              logoColor="white"
              borderRadius={1}
              hoverEnabled={true}
            />
          </div>
          <div className="flex gap-8">
            {selectedPage === NewReleaseActions.TAKEDOWN && selectedTakedownArtist && (
              <div className="flex gap-2">
                <img
                  style={{ width: 20, height: 20, borderRadius: 5 }}
                  src={selectedTakedownArtist.cover || "/account_icons/avatar.svg"}
                />
                <KSpan text={`${selectedTakedownArtist.label}`} color="#000" />
              </div>
            )}
            <div className="flex gap-2 pr-8" style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
              <img
                src="/navigation_icons/profile_dropdownmenu_icons/log-out-filled.svg"
                style={{ width: 20, height: 20 }}
              />
              <KSpan text={lang.catalog.new_release.exit} color="#000" />
            </div>
          </div>
        </div>
        <div
          className="flex"
          style={{
            height: `calc(100% - ${headerHeight}px)`,
            overflowY: "scroll",
            width: "100%",
            paddingTop: 24
          }}
        >
          <div style={{ width: "8%" }} />
          <div style={{ width: "20%" }}>
            <NavigationTab
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              releaseList={releaseList}
              selectedPage={selectedPage}
              selectedArtist={selectedTakedownArtist}
              user={user}
            />
          </div>
          <div style={{ width: "48%" }}>
            {selectedPage === NewReleaseActions.FINISH_RELEASE && (
              <FinishRelease
                user={user}
                releaseList={releaseList}
                setSelectedPage={setSelectedPage}
                selectRelease={selectRelease}
              />
            )}
            {selectedPage === NewReleaseActions.TAKEDOWN && (
              <Takedown
                user={user}
                setSelectedPage={setSelectedPage}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                selectedArtist={selectedTakedownArtist}
                setSelectedArtist={setSelectedTakedownArtist}
              />
            )}
          </div>
          <div style={{ width: "24%" }} />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    user: state.auth.user
  }
}

export default connect(mapStateToProps, { selectRelease })(NewReleaseAction)
