import React, { FC, useEffect, useState } from "react"
import { LCReleaseForm, SelectOption, User } from "../../../../../types"
import { Input } from "../../../../ui/form/Input"
import { InfoIcon } from "../../../../ui/icons"
import Tooltip from "../../../../ui/Tooltip"
import { Select } from "../../../../ui/form/select/Select"
import { SearchSelect } from "../../../../ui/form/select/SearchSelect"
import Button from "../../../../ui/form/Button"
import { Trash } from "heroicons-react"
import "react-datepicker/dist/react-datepicker.css"
import { releases } from "../../../../../apis"
import { KButton, KInput, KSelectDate, KSpan } from "kahuna-base-react-components"
import { AssetRegistryPages } from "../../../../../actions/types"
import Loading from "../../../../ui/Loading"
import { useNavigate } from "react-router-dom"
import { hashids, turkishCharMap } from "../../../../../utility"
import { toast } from "react-toastify"
import { loadingToastOptions } from "../../../../../constants"
import { lang } from "../../../../../constants/languages"
import {
  notAllowedOfferAttributes,
  notAllowedOfferRelationships,
  notAllowedProductAttributes,
  notAllowedProductRelationships,
  processCatalogNoString
} from "../../../../../utility/newReleaseHelpler"

interface GeneralInformationProps {
  releaseForm: LCReleaseForm
  setReleaseForm: (releaseForm: LCReleaseForm) => void
  options: any
  setSelectedTab: (selectedTab: number) => void
  setErrorText: (errorText?: string) => void
  artistOptions: SelectOption[]
  setArtistOptions: (artistOptions: SelectOption[]) => void
  onSearchArtist: (search: string) => void
  selectedArtistList: string[]
  setSelectedArtistList: (selectedArtistList: string[]) => void
  applyArtistToAll: (key: string, values: string[]) => void
  appleDigitalMaster: string
  setAppleDigitalMaster: (appleDigitalMaster: string) => void
  user: User
  isUrgent: boolean
  setIsUrgent: (isUrgent: boolean) => void
}

interface Contributor {
  [key: string]: string[]
}

const GeneralInformation: FC<GeneralInformationProps> = (props) => {
  const {
    releaseForm,
    setReleaseForm,
    setSelectedTab,
    setErrorText,
    artistOptions,
    setArtistOptions,
    onSearchArtist,
    setSelectedArtistList,
    selectedArtistList,
    applyArtistToAll,
    appleDigitalMaster,
    setAppleDigitalMaster,
    user,
    isUrgent,
    setIsUrgent
  } = props

  const [labelOptions, setLabelOptions] = useState(props.options.labelOptions)
  const [productTypeOptions, setProductTypeOptions] = useState(props.options.productTypeOptions)
  const [metadataLanguageOptions, setMetadataLanguageOptions] = useState(props.options.metadataLanguageOptions)
  const [productGenreOptions, setProductGenreOptions] = useState(props.options.productGenreOptions)
  const [distributorProductSubGenreOptions, setDistributorProductSubGenreOptions] = useState(
    props.options.distributorProductSubGenreOptions
  )
  const [distributorProductGenreOptions, setDistributorProductGenreOptions] = useState(
    props.options.distributorProductSubGenreOptions
  )
  const [distributorPriceCodeOptions, setDistributorPriceCodeOptions] = useState(
    props.options.distributorPriceCodeOptions
  )
  const [distributorTrackPriceOptions, setDistributorTrackPriceOptions] = useState(
    props.options.distributorTrackPriceOptions
  )
  const [roleOptions, setRoleOptions] = useState(props.options.roleOptions)
  const [contributors, setContributors] = useState<Contributor>({})
  const [selectedLabelName, setSelectedLabelName] = useState<string>(
    props.options.labelOptions?.find((label) => label.value === releaseForm.product.data.relationships.label?.data?.id)
      ?.label || ""
  )
  const [loading, setLoading] = useState(false)
  const [releaseDateMessage, setReleaseDateMessage] = useState("")

  const [checkSoundEngineer, setCheckSoundEngineer] = useState(appleDigitalMaster ? true : false)
  const [bookletDeleted, setBookletDeleted] = useState(false)
  const [selectedCalendarDate, setSelectedCalendarDate] = useState<Date | undefined>(undefined)

  const navigate = useNavigate()

  const resetRoleOptions = () => {
    setRoleOptions(
      props.options.roleOptions?.map((option) => {
        return { value: option, label: option }
      })
    )
  }

  useEffect(() => {
    setLabelOptions(props.options.labelOptions)
    setProductTypeOptions(props.options.productTypeOptions.filter((option) => option.label !== "Video"))
    setMetadataLanguageOptions(props.options.metadataLanguageOptions)
    setProductGenreOptions(props.options.productGenreOptions)
    setDistributorProductSubGenreOptions(props.options.distributorProductSubGenreOptions)
    setDistributorProductGenreOptions(props.options.distributorProductSubGenreOptions)
    setDistributorPriceCodeOptions(props.options.distributorPriceCodeOptions)
    setDistributorTrackPriceOptions(props.options.distributorTrackPriceOptions)
    resetRoleOptions()
  }, [props.options])

  const updateArtistAndContributors = (productAttributes) => {
    const contributors: Contributor = {}
    let artistList: string[] = []
    if (productAttributes.artist) {
      artistList = artistList.concat(productAttributes.artist)
    }

    Object.entries(productAttributes).forEach(([key, value]) => {
      if (props.options.roleOptions.includes(key) && value) {
        //@ts-ignore
        contributors[key] = value
        //@ts-ignore
        artistList = artistList.concat(value)
      }
    })
    setContributors({ ...contributors })
    setArtistOptions(
      artistList.map((artist: string) => {
        return { value: artist, label: artist }
      })
    )
    setSelectedArtistList([...artistList])
  }

  useEffect(() => {
    if (props.options.roleOptions) {
      updateArtistAndContributors(releaseForm.product.data.attributes)
    }
  }, [props.options.roleOptions])

  const updateProduct = (attributeName: string, value: any) => {
    setReleaseForm({
      ...releaseForm,
      product: {
        ...releaseForm.product,
        data: {
          ...releaseForm.product.data,
          attributes: {
            ...releaseForm.product.data.attributes,
            [attributeName]: value
          }
        }
      }
    })
  }

  const updateOffer = (attributeName: string, value: any) => {
    setReleaseForm({
      ...releaseForm,
      offer: {
        ...releaseForm.offer,
        data: {
          ...releaseForm.offer.data,
          attributes: {
            ...releaseForm.offer.data.attributes,
            [attributeName]: value
          }
        }
      }
    })
  }

  const updateOfferRelationships = (attributeName: string, value: any) => {
    setReleaseForm({
      ...releaseForm,
      offer: {
        ...releaseForm.offer,
        data: {
          ...releaseForm.offer.data,
          relationships: {
            ...releaseForm.offer.data.relationships,
            [attributeName]: value
          }
        }
      }
    })
  }

  const updateProductRelationships = (attributeName: string, value: any) => {
    setReleaseForm({
      ...releaseForm,
      product: {
        ...releaseForm.product,
        data: {
          ...releaseForm.product.data,
          relationships: {
            ...releaseForm.product.data.relationships,
            [attributeName]: value
          }
        }
      }
    })
  }

  const handleImageChange = (e: any, attributeName: string) => {
    const file = e.target.files[0]

    if (file) {
      const reader = new FileReader()

      reader.onloadend = () => {
        updateProduct(attributeName, reader.result)
      }

      reader.readAsDataURL(file)
    }
  }

  const onSearchTextChanged = async (event, options, setOptions) => {
    setOptions(options.filter((option) => option.label.toLowerCase().includes(event.target.value.toLowerCase())))
  }

  const onRoleTextChanged = async (event, options, setOptions) => {
    const alreadySelectedOptions = Object.keys(contributors)
      .filter((contributor) => contributor !== "new")
      .map((contributor) => {
        return { label: contributor, value: contributor }
      })

    setOptions([
      ...options.filter((option) => option.label.toLowerCase().includes(event.target.value.toLowerCase())),
      ...alreadySelectedOptions
    ])
  }

  const tooltip = (width: string, text: string, marginTop: string) => {
    return (
      <div className="relative">
        <Tooltip
          disableBackground={true}
          content={
            <p
              style={{
                width,
                padding: 5,
                borderRadius: 10,
                backgroundColor: "rgba(0,0,0,0.8)",
                color: "white"
              }}
            >
              {text}
            </p>
          }
          type="info"
        >
          <InfoIcon className={`w-3.5 h-3.5 ${marginTop} ml-1`} />
        </Tooltip>
      </div>
    )
  }

  const deleteBooklet = () => {
    setBookletDeleted(true)
    setReleaseForm({
      ...releaseForm,
      product: {
        ...releaseForm.product,
        data: {
          ...releaseForm.product.data,
          attributes: {
            ...releaseForm.product.data.attributes,
            booklet: "",
            "booklet-url": ""
          }
        }
      }
    })
  }

  const saveForm = () => {
    if (!releaseForm.product.data.attributes["name"]) {
      setErrorText(lang.catalog.asset_registry.general_information.error_text.album_title_blank)
      return
    }

    const productTypeId = releaseForm.product.data.relationships["product-type"]?.data?.id
    if (!productTypeId) {
      setErrorText(lang.catalog.asset_registry.general_information.error_text.product_type_blank)
      return
    }

    const MainGenreId = releaseForm.offer.data.relationships["subgenre"]?.data?.id
    if (!MainGenreId) {
      setErrorText(lang.catalog.asset_registry.general_information.error_text.main_genre_blank)
      return
    }

    if (!releaseForm.offer.data.attributes["release-date"]) {
      setErrorText(lang.catalog.asset_registry.general_information.error_text.release_date_blank)
      return
    }

    setLoading(true)
    const productRelationships = {}
    Object.entries(releaseForm.product.data.relationships).forEach(([key, value]) => {
      if (value["data"] && !notAllowedProductRelationships.includes(key)) {
        productRelationships[key] = { data: value["data"] }
      }
    })
    const productAttributes = {}
    Object.entries(releaseForm.product.data.attributes).forEach(([key, value]) => {
      if (value && !notAllowedProductAttributes.includes(key)) productAttributes[key] = value
    })

    if (bookletDeleted) {
      productAttributes["booklet"] = ""
    }

    const offerRelationships = {}
    Object.entries(releaseForm.offer.data.relationships).forEach(([key, value]) => {
      if (value["data"] && !notAllowedOfferRelationships.includes(key)) {
        offerRelationships[key] = { data: value["data"] }
      }
    })
    const offerAttributes = {}
    Object.entries(releaseForm.offer.data.attributes).forEach(([key, value]) => {
      if (value && !notAllowedOfferAttributes.includes(key)) offerAttributes[key] = value
    })

    const labelId = releaseForm.product.data.relationships.label?.data?.id
    const labelInternalReference = processCatalogNoString(productAttributes["name"], labelId)
    const trackPriceRelationship = { data: { type: "distributor-price-codes", id: "35136" } }

    let distributorPriceId = ""
    if (productTypeId === "1") distributorPriceId = "35130" // Album => BA - $5.99 USD
    else if (productTypeId === "2") distributorPriceId = "35126" // Single => S1 - $0.99 USD
    else if (productTypeId === "8") distributorPriceId = "35127" // EP => BEP - $1.99 USD

    const distributorPriceRelationship = { data: { type: "distributor-price-codes", id: distributorPriceId } }

    const filteredReleaseForm = {
      label_name: selectedLabelName,
      product: {
        data: {
          id: releaseForm.product.data.id,
          attributes: {
            ...productAttributes,
            "label-internal-reference": labelInternalReference,
            "is-compilation": false
          },
          relationships: productRelationships,
          type: "products"
        }
      },
      offer: {
        data: {
          id: releaseForm.offer.data.id,
          attributes: offerAttributes,
          relationships: {
            ...offerRelationships,
            "track-price-code": trackPriceRelationship,
            "price-code": distributorPriceRelationship
          },
          type: "offers"
        }
      }
    }

    if (appleDigitalMaster) {
      filteredReleaseForm["apple_digital_master"] = appleDigitalMaster
    }
    filteredReleaseForm["is_urgent"] = isUrgent

    try {
      releases(`/release/label_camp_product_save/`, "post", { ...filteredReleaseForm }).then((resp) => {
        if (!releaseForm.product.data.id && resp.data.release_id) {
          navigate(`/catalog/asset-registry/${hashids.encode(resp.data.release_id.toString() || "")}`)
        }

        if (!resp.data.success) {
          if (resp.data.upc && resp.data.product_id) {
            setReleaseForm({
              ...releaseForm,
              product: {
                ...releaseForm.product,
                data: {
                  ...releaseForm.product.data,
                  id: resp.data.product_id,
                  attributes: {
                    ...releaseForm.product.data.attributes,
                    "upc-code": resp.data.upc
                  }
                }
              }
            })
          }
          setErrorText(`${JSON.stringify(resp.data.result)}`)
          setLoading(false)
          return
        }
        if (resp.data.warning) {
          toast.success(resp.data.warning, loadingToastOptions)
        }
        const data = resp.data.result
        setReleaseForm({
          ...releaseForm,
          product: data.product,
          offer: data.offer
        })
        updateArtistAndContributors(data.product.data.attributes)
        setLoading(false)
        setSelectedTab(AssetRegistryPages.TRACKS_AND_ASSETS)
      })
    } catch (e) {
      console.error("Error saving product")
      setErrorText(e)
      // toast.error(`Error Saving Product`, errorToastOptions)
      setLoading(false)
    }
  }

  const onClickArtistDublicate = (key: string) => {
    const values = releaseForm.product.data.attributes[key]
    applyArtistToAll(key, values)
  }

  const onSelectReleaseDate = (date: Date | undefined, isUrgent: boolean) => {
    setSelectedCalendarDate(date)
    if (!date) {
      updateOffer("release-date", undefined)
      setReleaseDateMessage(lang.catalog.asset_registry.general_information.release_date_message.missing)
      return
    }
    date.setHours(12)

    const minAllowedDay = isUrgent ? 1 : 7
    const errorMessage = isUrgent ? `1 ${lang.common.day}` : `1 ${lang.common.week}`

    const dateMinLimit = new Date()
    dateMinLimit.setDate(dateMinLimit.getDate() + minAllowedDay)

    const dateWarningLimit = new Date()
    dateWarningLimit.setDate(dateMinLimit.getDate() + 21)

    if (dateMinLimit.getTime() > date.getTime()) {
      updateOffer("release-date", undefined)
      const newMessage = lang.catalog.asset_registry.general_information.release_date_message.date_gap.replace(
        "<param>",
        errorMessage
      )
      setReleaseDateMessage(newMessage)
      return
    } else if (dateWarningLimit.getTime() > date.getTime()) {
      setReleaseDateMessage(lang.catalog.asset_registry.general_information.release_date_message.recommended)
    } else {
      setReleaseDateMessage("")
    }

    updateOffer("release-date", date?.toISOString())
  }

  const onClickUrgent = (value: boolean) => {
    setIsUrgent(value)
    if (selectedCalendarDate) {
      onSelectReleaseDate(new Date(selectedCalendarDate), value)
    }
  }

  const renderReleaseDateView = () => {
    const releaseDate = releaseForm.offer.data.attributes["release-date"]
    const urgentReleaseCount = user.userCredit?.urgentNewRelease || 0

    return (
      <div>
        <div className={"mt-3 flex"}>
          <p>{lang.catalog.asset_registry.general_information.release_date}: </p>
          <p
            className="ml-2"
            style={{
              color: releaseDate ? "#111" : "red",
              fontStyle: releaseDate ? "normal" : "italic"
            }}
          >
            {releaseDate?.substring(0, 10) || releaseDateMessage}
          </p>
          {releaseDate && releaseDateMessage && (
            <p className="ml-2" style={{ color: "#11111150", fontStyle: "italic" }}>
              {releaseDateMessage}
            </p>
          )}
        </div>
        {urgentReleaseCount > 0 && (
          <div className="flex mt-4 gap-2">
            <KInput
              value={""}
              padding="0px"
              type="checkbox"
              accentColor="black"
              shadowDisabled={true}
              background="white"
              checked={isUrgent}
              onChange={() => onClickUrgent(!isUrgent)}
            />
            <KSpan text={lang.catalog.asset_registry.general_information.urgent_release} />
          </div>
        )}
      </div>
    )
  }

  const artistSelection = (isMainArtist: boolean, key: string) => {
    if (!roleOptions) {
      return undefined
    }
    const options = props.options.roleOptions?.map((option) => {
      return { value: option, label: option }
    })

    return (
      <div className={"flex border pb-2 pt-2"} key={key}>
        <div className={"relative"} style={{ width: "25%", borderRight: "1px solid rgb(185, 185, 185)" }}>
          <SearchSelect
            className={"mt-1 border-0"}
            placeholder={lang.catalog.asset_registry.general_information.select_role_placeholder}
            containerStyle={{ border: 0, boxShadow: "none" }}
            inputTextClass="text-[16px]"
            disabledOptions={["artist"]}
            options={isMainArtist ? [{ value: "artist", label: "Main Artist" }] : roleOptions}
            onTextChange={(event) => onRoleTextChanged(event, options, setRoleOptions)}
            value={releaseForm.product.data.attributes[key] ? key : ""}
            //@ts-ignore
            onChange={(element: string) => {
              delete contributors[key]
              delete releaseForm.product.data.attributes[key]
              setContributors({ ...contributors, [element || "new"]: [] })
              updateProduct(element, [])
              resetRoleOptions()
            }}
          />
        </div>
        <div className={"relative"} style={{ width: "67%" }}>
          <SearchSelect
            key={`search-select-${key}`}
            className={"mt-1 border-0"}
            placeholder={lang.catalog.asset_registry.general_information.select_artist_placeholder}
            containerStyle={{ border: 0, boxShadow: "none" }}
            inputTextClass="text-[16px]"
            multiple
            options={artistOptions}
            onTextChange={(event) => onSearchArtist(event.target.value)}
            value={releaseForm.product.data.attributes[key] || []}
            //@ts-ignore
            onChange={(element: string[]) => {
              if (!isMainArtist) {
                setContributors({ ...contributors, [key]: element })
              }
              updateProduct(key, element)
              const artistList = selectedArtistList.concat(element)

              const optionList = artistOptions
                .filter((option) => artistList.includes(option.value.toString()))
                .map((option) => {
                  if (option.label === "+ Add New") {
                    option.label = option.value
                  }
                  return option
                })
              setArtistOptions(optionList)
              setSelectedArtistList([...artistList])
            }}
          />
        </div>
        <div className={"flex items-center justify-center"} style={{ width: "4%" }}>
          <div className="relative">
            <Tooltip
              width={100}
              place="bottom"
              content={
                <p className="asset-registry-span text-white">
                  {lang.catalog.asset_registry.general_information.apply_all_tooltip.replace("<param>", key)}
                </p>
              }
            >
              <img
                className="mt-1"
                src="/catalog_icons/files-plus.svg"
                style={{ cursor: "pointer" }}
                onClick={() => onClickArtistDublicate(key)}
              />
            </Tooltip>
          </div>
        </div>
        <div className={"flex items-center justify-center"} style={{ width: "4%" }}>
          <Trash
            className="h-10 mt-1 cursor-pointer"
            onClick={() => {
              if (isMainArtist) {
                releaseForm.product.data.attributes["artist"] = []
              } else {
                delete contributors[key]
                delete releaseForm.product.data.attributes[key]
              }
              setContributors({ ...contributors })
              setReleaseForm({ ...releaseForm })
              resetRoleOptions()
            }}
          />
        </div>
      </div>
    )
  }

  return (
    <div>
      {loading && <Loading />}
      <div className={"flex"}>
        <div style={{ width: "75%" }}>
          <div className={"m-4 bg-white"}>
            <div className={"p-4"}>
              <p className={"font-bold text-md asset-registry-title"}>
                {lang.catalog.asset_registry.general_information.title}
              </p>
              <div className={"flex mt-5"}>
                <div style={{ width: "75%" }}>
                  <p className={"text-gray-500 asset-registry-span"}>
                    {lang.catalog.asset_registry.general_information.album_title}
                    <span style={{ color: "red" }}>&#42;</span>
                  </p>
                </div>
                <div style={{ width: "25%", display: "flex" }}>
                  <p className={"text-gray-500 asset-registry-span"}>
                    {lang.catalog.asset_registry.general_information.title_version}
                  </p>
                  {tooltip("300px", lang.catalog.asset_registry.general_information.title_version_tooltip, "mt-1")}
                </div>
              </div>
              <div className={"flex"}>
                <div style={{ width: "75%", paddingRight: 20 }}>
                  <Input
                    className={`border mt-2 mb-2`}
                    placeholder={""}
                    value={releaseForm.product.data.attributes.name}
                    onChange={(event) => updateProduct("name", event.target.value)}
                  />
                </div>
                <div style={{ width: "25%" }}>
                  <Input
                    className={`border mt-2 mb-2`}
                    placeholder={""}
                    value={releaseForm.product.data.attributes.subtitle}
                    onChange={(event) => updateProduct("subtitle", event.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={"m-4 bg-white"}>
            <div className={"p-4"}>
              <p className={"font-bold mb-5 asset-registry-title"}>
                {lang.catalog.asset_registry.general_information.information}
              </p>
              <div className={"flex"}>
                <div style={{ width: "50%", paddingRight: 15 }} id="parent-div">
                  <p className={"text-gray-500 asset-registry-span"}>
                    {lang.catalog.asset_registry.general_information.label}
                    <span style={{ color: "red" }}>&#42;</span>
                  </p>
                  <div className={"relative"}>
                    <SearchSelect
                      className={"mt-2"}
                      placeholder={lang.catalog.asset_registry.general_information.label_placeholder}
                      inputTextClass="text-[16px]"
                      options={labelOptions}
                      onTextChange={(event) => onSearchTextChanged(event, props.options.labelOptions, setLabelOptions)}
                      value={releaseForm.product.data.relationships.label?.data?.id}
                      onChange={(element) => {
                        setSelectedLabelName(
                          props.options.labelOptions.find((label) => label.value === element)?.label || ""
                        )
                        updateProductRelationships("label", {
                          data: { type: "labels", id: element }
                        })
                      }}
                    />
                  </div>

                  <p className={"text-gray-500 mt-5 asset-registry-span"}>
                    {lang.catalog.asset_registry.general_information.product_type}
                    <span style={{ color: "red" }}>&#42;</span>
                  </p>
                  <div className="relative">
                    <Select
                      className={"mt-2"}
                      placeholder={lang.catalog.asset_registry.general_information.product_type_placeholder}
                      inputTextClass="text-[16px]"
                      options={productTypeOptions}
                      value={releaseForm.product.data.relationships["product-type"]?.data?.id}
                      onSelect={(element) => {
                        updateProductRelationships("product-type", {
                          data: { type: "product-types", id: element }
                        })
                      }}
                    />
                  </div>

                  <div className={"flex"}>
                    <p className={"text-gray-500 mt-5 asset-registry-span"}>
                      {lang.catalog.asset_registry.general_information.copyright_line}
                      <span style={{ color: "red" }}>&#42;</span>
                    </p>
                    {tooltip("250px", lang.catalog.asset_registry.general_information.copyright_line_tooltip, "mt-6")}
                  </div>
                  <Input
                    className={`border mt-2 mb-2`}
                    placeholder={""}
                    value={releaseForm.product.data.attributes["copyright-line"]}
                    onChange={(event) => updateProduct("copyright-line", event.target.value)}
                  />
                </div>
                <div style={{ width: "50%" }}>
                  {/***
                      <p className={"text-gray-500 asset-registry-span"}>
                        UPC<span style={{ color: "red" }}>&#42;</span>
                      </p>
                      <Input
                        className={`border mt-2 mb-2`}
                        placeholder={""}
                        value={releaseForm.product.data.attributes["upc-code"]}
                        onChange={(event) => updateProduct("upc-code", event.target.value)}
                      />
                     */}
                  <p className={"text-gray-500 asset-registry-span"}>
                    {lang.catalog.asset_registry.general_information.metadata_language}
                    <span style={{ color: "red" }}>&#42;</span>
                  </p>
                  <div className={"relative"}>
                    <SearchSelect
                      className={"mt-2"}
                      placeholder={lang.catalog.asset_registry.general_information.metadata_placeholder}
                      inputTextClass="text-[16px]"
                      options={metadataLanguageOptions}
                      onTextChange={(event) =>
                        onSearchTextChanged(event, props.options.metadataLanguageOptions, setMetadataLanguageOptions)
                      }
                      value={releaseForm.product.data.relationships.language?.data?.id}
                      onChange={(element) => {
                        updateProductRelationships("language", {
                          data: { type: "languages", id: element }
                        })
                      }}
                    />
                  </div>
                  <p className={"text-gray-500 mt-5 asset-registry-span"}>
                    {lang.catalog.asset_registry.general_information.production_year}
                  </p>
                  <Input
                    className={`border mt-2 mb-2`}
                    placeholder={""}
                    value={releaseForm.product.data.attributes["production-year"]}
                    onChange={(event) => updateProduct("production-year", event.target.value)}
                  />

                  <div className={"flex"}>
                    <p className={"text-gray-500 mt-2 asset-registry-span"}>
                      {lang.catalog.asset_registry.general_information.production_line}
                      <span style={{ color: "red" }}>&#42;</span>
                    </p>
                    {tooltip("300px", lang.catalog.asset_registry.general_information.production_line_tooltip, "mt-3")}
                  </div>
                  <Input
                    className={`border mt-3 mb-2`}
                    placeholder={""}
                    value={releaseForm.product.data.attributes["production-line"]}
                    onChange={(event) => updateProduct("production-line", event.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ width: "25%" }}>
          <div className={"m-4 bg-white"}>
            <div className={"p-4"}>
              <div className={"flex"}>
                <p className={"font-bold asset-registry-title"}>
                  {lang.catalog.asset_registry.general_information.cover_art}
                  <span style={{ color: "red" }}>&#42;</span>
                </p>
                {tooltip("150px", lang.catalog.asset_registry.general_information.cover_art_tooltip, "mt-1")}
              </div>
              <div className={"mt-2"}>
                <input type="file" onChange={(e) => handleImageChange(e, "front-cover")} className={"mb-3"} />
                {releaseForm.product.data.attributes["front-cover"] && (
                  <img
                    src={releaseForm.product.data.attributes["front-cover"]}
                    alt="Uploaded"
                    style={{ width: "300px" }}
                  />
                )}
                {releaseForm.product.data.attributes["full-cover-url"] &&
                  !releaseForm.product.data.attributes["front-cover"] && (
                    <img
                      src={releaseForm.product.data.attributes["full-cover-url"]}
                      alt="Uploaded"
                      style={{ width: "300px" }}
                    />
                  )}
              </div>
              <div className={"flex mt-5"}>
                <p className={"font-bold asset-registry-title"}>
                  {lang.catalog.asset_registry.general_information.booklet}
                </p>
                {tooltip("150px", lang.catalog.asset_registry.general_information.booklet_tooltip, "mt-1")}
              </div>
              <div className={"mt-2"}>
                <input
                  type="file"
                  onChange={(e) => {
                    setBookletDeleted(false)
                    handleImageChange(e, "booklet")
                  }}
                  className={"mb-3"}
                  accept=".pdf"
                />
                {releaseForm.product.data.attributes["booklet"] && (
                  <div>
                    <img
                      className="cursor-pointer mb-3"
                      src="/catalog_icons/delete-bin.svg"
                      onClick={() => deleteBooklet()}
                    />
                    <embed
                      src={releaseForm.product.data.attributes["booklet"]}
                      type="application/pdf"
                      width="100%"
                      height="600px"
                    />
                  </div>
                )}
                {releaseForm.product.data.attributes["booklet-url"] &&
                  !releaseForm.product.data.attributes["booklet"] && (
                    <div>
                      <img
                        className="cursor-pointer mb-3"
                        src="/catalog_icons/delete-bin.svg"
                        onClick={() => deleteBooklet()}
                      />
                      <embed
                        src={releaseForm.product.data.attributes["booklet-url"]}
                        type="application/pdf"
                        width="100%"
                        height="600px"
                      />
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"flex"}>
        <div className={"m-4 p-4 bg-white"} style={{ width: "100%" }}>
          <div>
            <p className={"font-bold asset-registry-title"}>
              {lang.catalog.asset_registry.general_information.artists}
              <span style={{ color: "red" }}>&#42;</span>
            </p>
          </div>
          <div className={"mt-2"}>
            <p className={"text-gray-500 asset-registry-span"}>
              {lang.catalog.asset_registry.general_information.enter_main_artist_desc}
            </p>
          </div>
          <div className={"mt-2"}>{artistSelection(true, "artist")}</div>
          <div className={"mt-6 flex"}>
            <p className={"font-bold asset-registry-title"}>
              {lang.catalog.asset_registry.general_information.contributors}
            </p>
            {tooltip("300px", lang.catalog.asset_registry.general_information.contributors_tooltip, "mt-1")}
          </div>
          <div className={"mt-2"}>
            <p className={"text-gray-500 asset-registry-span"}>
              {lang.catalog.asset_registry.general_information.add_contributor_desc}
            </p>
          </div>
          <div className={"mt-2"}>
            {Object.entries(contributors).map(([key, value]) => {
              return artistSelection(false, key)
            })}
          </div>
          <div className={"mt-2"}>
            <Button
              text={lang.catalog.asset_registry.general_information.add_contributor_button}
              onClick={() => {
                setContributors({ ...contributors, new: [] })
              }}
              light
              buttonType="button"
            />
          </div>
          <div className={"mt-6"}>
            <p className={"font-bold asset-registry-title"}>
              {lang.catalog.asset_registry.general_information.apple_digital_master}
            </p>
          </div>
          <div className={"mt-2 flex"}>
            <input
              style={{ cursor: "pointer" }}
              type="checkbox"
              checked={checkSoundEngineer}
              onChange={() => {
                const updatedValue = !checkSoundEngineer
                if (!updatedValue) {
                  setAppleDigitalMaster("")
                }
                setCheckSoundEngineer(updatedValue)
              }}
            />
            <p className={"text-gray-500 pl-2 asset-registry-span"}>
              {lang.catalog.asset_registry.general_information.apple_digital_master_desc}
            </p>
          </div>
          {checkSoundEngineer && (
            <>
              <div className={"mt-3"}>
                <p className={"text-gray-500 asset-registry-span"}>
                  {lang.catalog.asset_registry.general_information.sound_engineer_email}
                </p>
              </div>
              <Input
                className={`border mt-2 mb-2 w-[500px]`}
                placeholder={""}
                value={appleDigitalMaster}
                onChange={(event) => setAppleDigitalMaster(event.target.value)}
              />
            </>
          )}
        </div>
      </div>
      <div className={"flex"}>
        <div className={"m-4 bg-white p-4"} style={{ width: "100%" }}>
          <div>
            <p className={"font-bold asset-registry-title"}>
              {lang.catalog.asset_registry.general_information.distribution}
            </p>
          </div>
          {renderReleaseDateView()}
          <div className={"mt-6 flex"}>
            <div style={{ width: "50%" }}>
              <KSelectDate
                minimumDate={new Date()}
                value={
                  releaseForm.offer.data.attributes["release-date"] &&
                  new Date(releaseForm.offer.data.attributes["release-date"])
                }
                onChange={(date: Date | undefined) => onSelectReleaseDate(date, isUrgent)}
              />
            </div>
            <div style={{ width: "50%" }}>
              <div style={{ width: "100%", paddingLeft: 64 }}>
                <p className={"text-gray-500 asset-registry-span"}>
                  {lang.catalog.asset_registry.general_information.main_genre}
                  <span style={{ color: "red" }}>&#42;</span>
                </p>
                <div className={"relative"}>
                  <SearchSelect
                    className={"mt-2"}
                    placeholder={lang.catalog.asset_registry.general_information.main_genre_placeholder}
                    inputTextClass="text-[16px]"
                    options={distributorProductGenreOptions}
                    onTextChange={(event) =>
                      onSearchTextChanged(
                        event,
                        props.options.distributorProductSubGenreOptions,
                        setDistributorProductGenreOptions
                      )
                    }
                    value={releaseForm.offer.data.relationships["subgenre"]?.data?.id}
                    onChange={(element) => {
                      updateOfferRelationships("subgenre", {
                        data: { type: "distributor-product-subgenres", id: element }
                      })
                    }}
                  />
                </div>
              </div>
              <div style={{ width: "100%", paddingLeft: 64 }} className="mt-6">
                <p className={"text-gray-500 asset-registry-span"}>
                  {lang.catalog.asset_registry.general_information.secondary_genre}
                </p>
                <div className={"relative"}>
                  <SearchSelect
                    className={"mt-2"}
                    placeholder={lang.catalog.asset_registry.general_information.secondary_genre_placeholder}
                    inputTextClass="text-[16px]"
                    options={distributorProductSubGenreOptions}
                    onTextChange={(event) =>
                      onSearchTextChanged(
                        event,
                        props.options.distributorProductSubGenreOptions,
                        setDistributorProductSubGenreOptions
                      )
                    }
                    value={releaseForm.offer.data.relationships["subgenre2"]?.data?.id}
                    onChange={(element) => {
                      updateOfferRelationships("subgenre2", {
                        data: { type: "distributor-product-subgenres", id: element }
                      })
                    }}
                  />
                </div>
              </div>
            </div>
            {/**
            <div style={{ width: "25%" }}>
              <p className={"text-gray-500 asset-registry-span"}>Release Date</p>
              <DatePicker
                selected={
                  releaseForm.offer.data.attributes["release-date"] &&
                  new Date(releaseForm.offer.data.attributes["release-date"])
                }
                showIcon
                className={"border-2 mt-2 rounded"}
                calendarIconClassname={"mt-3"}
                onChange={(date) => {
                  updateOffer("release-date", date?.toISOString())
                }}
              />
            </div>
             <div style={{ width: "25%" }}>
              <p className={"text-gray-500 asset-registry-span"}>Preorder Date</p>
              <DatePicker
                selected={
                  releaseForm.offer.data.attributes["preorder-date"] &&
                  new Date(releaseForm.offer.data.attributes["preorder-date"])
                }
                showIcon
                className={"border-2 mt-2 rounded"}
                calendarIconClassname={"mt-3"}
                onChange={(date) => {
                  updateOffer("preorder-date", date?.toISOString())
                }}
              />
            </div>
            <div style={{ width: "25%" }}>
              <div className={"flex"}>
                <p className={"text-gray-500 asset-registry-span"}>Original Release Date</p>
                {tooltip(
                  "300px",
                  "Mandatory if the album has already been released before (digitally or physically)",
                  "mt-1"
                )}
              </div>
              <DatePicker
                selected={
                  releaseForm.product.data.attributes["original-release-date"] &&
                  new Date(releaseForm.product.data.attributes["original-release-date"])
                }
                showIcon
                className={"border-2 mt-2 rounded"}
                calendarIconClassname={"mt-3"}
                onChange={(date) => {
                  updateProduct("original-release-date", date?.toISOString())
                }}
              />
            </div>
             */}
          </div>
          {/***
             * <div>
            <p className={"font-bold mt-7 asset-registry-title"}>Album Price</p>
          </div>
          <div className={"mt-3 flex"}>
            <div style={{ width: "25%", paddingRight: 15 }}>
              <p className={"text-gray-500 asset-registry-span"}>
                Price<span style={{ color: "red" }}>&#42;</span>
              </p>
              <div className={"relative"}>
                <SearchSelect
                  className={"mt-2"}
                  placeholder={"Select Price..."}
                  inputTextClass="text-[16px]"
                  options={distributorPriceCodeOptions}
                  onTextChange={(event) =>
                    onSearchTextChanged(
                      event,
                      props.options.distributorPriceCodeOptions,
                      setDistributorPriceCodeOptions
                    )
                  }
                  value={releaseForm.offer.data.relationships["price-code"]?.data?.id}
                  onChange={(element) => {
                    updateOfferRelationships("price-code", {
                      data: { type: "distributor-price-codes", id: element }
                    })
                  }}
                />
              </div>
            </div>
            <div style={{ width: "25%" }}>
              <p className={"text-gray-500 asset-registry-span"}>
                Track Price<span style={{ color: "red" }}>&#42;</span>
              </p>
              <div className={"relative"}>
                <SearchSelect
                  className={"mt-2"}
                  placeholder={"Select Track Price..."}
                  inputTextClass="text-[16px]"
                  options={distributorTrackPriceOptions}
                  onTextChange={(event) =>
                    onSearchTextChanged(
                      event,
                      props.options.distributorTrackPriceOptions,
                      setDistributorTrackPriceOptions
                    )
                  }
                  value={releaseForm.offer.data.relationships["track-price-code"]?.data?.id}
                  onChange={(element) => {
                    updateOfferRelationships("track-price-code", {
                      data: { type: "distributor-price-codes", id: element }
                    })
                  }}
                />
              </div>
            </div>
          </div>
             */}
        </div>
      </div>
      <div className={"flex justify-end"} style={{ marginBottom: 40, marginTop: 40, marginRight: 40 }}>
        <KButton
          width="400px"
          text={lang.catalog.asset_registry.general_information.save_and_next_step_button}
          disabled={loading}
          onClick={() => saveForm()}
          background="black"
          textColor="white"
        />
      </div>
    </div>
  )
}

export default GeneralInformation
